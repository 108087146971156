.provider-rating-info {
  text-align: center;
  margin: 0px 0px 24px 0px;

  div {
    padding: 8px 18px;
  }

  i {
    color: $gray-light;
    margin-left: 8px;
    font-size: 16px;
  }
}
