/* ==========================================================================
 * Styles for specific modals
 * @see components/_modal.scss for modal styling in general
 * ========================================================================== */

.fade.in {
  display: block;
}

.email-capture-popup {
  padding: 20px;
  font-size: 13px;
  width: 440px;
}

.email-capture-popup-initial {
  background: url(../images/envelope.png) no-repeat scroll 330px 0px transparent;
}

.email-capture-popup-tagline {
  padding: 10px 0;
}

.email-capture-popup-input {
  width: 320px;
}

.email-capture-popup-thanks {
  display: none;
  padding: 0 0 0 35px;
  background: url(../images/green-check.png) no-repeat scroll 58px 4px transparent;
}

.rover-modal.price-suggestion-modal {
  .modal-content {
    border: 0;
    background: $primary-1;
    color: white;
    padding: 20px;

    a {
      color: white;
    }
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@media (min-width: $screen-sm-min) {
  .modal-blocking-modal {
    width: 550px;
  }
}

.blocking-modal-content {
  .rover-header-title {
    @include responsive-spacing(margin-top, 0);
    @include responsive-spacing(margin-bottom, 2);
  }

  .asteriskField {
    display: none;
  }

  .button-columns {
    label {
      @include responsive-spacing(margin-top, 3, true);
    }
  }

  .control-label {
    @include responsive-spacing(margin-top, 2, true);
    @include responsive-spacing(margin-bottom, 0, true);
    font-weight: 700;
  }
}

// Rover Modals
.rover-modal {
  /*
     * Performance Metrics: Score Breakdown Content Modal
     */
  &.performance-metrics__score-breakdown {
    .modal-content {
      padding: 0;
    }
  }
}
