.staff-message-review {
  padding-top: 0px !important;

  .header,
  .header-toolbar-wrapper,
  .footer {
    display: none;
  }

  .message-review tr {
    height: 250px;
  }
  .message-review tr.table-heading {
    height: auto;
  }
  .message-review > tbody > tr > td {
    vertical-align: middle;
  }
  .conv-data-container {
    width: 350px;
  }
  .delayed > td {
    background-color: rgba($notice, 0.2) !important;
  }
  .conv-data tr {
    height: auto;
  }
  .message-review-form-container {
    width: 200px;
  }
  .banned-message-count {
    vertical-align: middle;
  }
  .pet-list {
    list-style: none;
    padding: 0;
  }
}
