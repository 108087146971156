.vertical-progress {
  @include clearfix;
  counter-reset: vertical-progress-counter;
  $marker-size: 28px;
  $marker-top: calc($line-height-computed / 2);

  @include not-xs {
    .vertical-progress-section {
      @include responsive-spacing('padding-left', 6);
      border-left: 1px solid $border-color;
      position: relative;
      width: 100%;
      float: left;
      min-height: 85px;

      &.vertical-progress-section-last::before {
        content: '';
        width: 3px;
        background: white;
        position: absolute;
        bottom: 0;
        left: -1px;
        top: calc($marker-top + $marker-size / 2);
      }

      &.vertical-progress-section-first::before {
        content: '';
        width: 3px;
        background: white;
        position: absolute;
        top: 0;
        left: -1px;
        height: calc($marker-top + $marker-size / 2);
      }

      & > .summary {
        color: $gray-3;
        opacity: 0;
        position: absolute;
        top: 50px;
        left: 30px;
        transition-delay: 0s;
        transition: opacity 0s;
      }

      & > .content {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
      }

      &.collapsed {
        & > .content {
          max-height: 0;
        }

        & > .summary {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
          transition-delay: 0.45s;
        }
      }
    }

    .vertical-progress-marker {
      background-color: white;
      line-height: $marker-size - 4;
      width: $marker-size;
      height: $marker-size;
      border-radius: calc($marker-size / 2);
      border: 1px solid $border-color;
      padding-left: 1px;
      padding-top: 1px;
      color: $gray-2;
      font-weight: 700;
      text-align: center;
      z-index: $zindex-navbar-fixed - 1;
      position: absolute;
      left: calc($marker-size / -2);
      top: $marker-top;
      font-size: 12px;

      &::after {
        content: counter(vertical-progress-counter);
        counter-increment: vertical-progress-counter;
      }

      &.active {
        color: $primary-1;
        border: 2px solid $primary-1;
        padding-left: 0px;
        padding-top: 0px;
      }

      &.completed {
        font-size: 10px;

        &::after {
          content: '\e912';
          font-family: 'rover-icons';
          font-weight: normal;
        }
      }
    }
  }

  @include xs-only {
    .vertical-progress-marker {
      @include responsive-spacing('margin-top', 4);

      &::before,
      &::after {
        display: inline-block;
        color: $primary-1;
      }
      &::before {
        @include responsive-spacing('margin-right', 2);
        @include small-text;
        content: '\e915';
        font-family: 'rover-icons';
        font-weight: normal;
      }
      &::after {
        content: 'Step ' counter(vertical-progress-counter);
        counter-increment: vertical-progress-counter;
        font-weight: bold;
      }

      &.completed::before {
        content: '\e912';
      }
    }
  }
}
