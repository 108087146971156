.safety-quiz {
  .rover-page-header {
    display: block;
  }

  .rover-primary-content-block {
    box-shadow: none;
  }

  .rover-widget-block {
    box-shadow: none;

    .pretty-buttons input[type='radio'] {
      @extend .sr-only;
    }

    input[type='radio']:checked + div.rover-btn {
      background-color: $primary-1;
      border-color: $primary-1;
      color: white;
    }

    label {
      .rover-btn {
        float: left;
        font-weight: bold;
        margin-left: 3px;
      }
      .rover-btn:hover {
        border-color: $primary-1;
        color: $primary-1;
      }

      &:focus-within .rover-btn {
        @include tab-focus();
      }
    }

    .choice-text-wrapper {
      margin-left: 60px;
    }

    .rover-widget-strong {
      color: white;
      @include responsive-spacing(padding, 3);
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
    }

    .rover-widget-title {
      margin-bottom: 0;
    }

    .progress {
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 0;
    }

    .row {
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      border-left: 1px solid $border-color;

      &.rover-widget-submit-section {
        text-align: center;
        @include responsive-spacing(padding, 3);
        & > div {
          margin-top: 10px;
        }
      }

      label {
        @include responsive-spacing(padding-top, 2);
        @include responsive-spacing(padding-bottom, 2);
        display: block;
        overflow: hidden;
      }

      &.form-error-row {
        border-bottom: 0;
      }
    }

    input[type='submit'] {
      /* Override for sitter signup global override */
      padding: 8px 16px;
      margin-bottom: 15px;
    }

    .alert {
      @include responsive-spacing(margin-top, 2);
    }

    @include xs-only {
      .rover-widget-title {
        padding: 15px 30px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .row {
        padding: 0 10px;
      }
    }
  }
}
