/* ==========================================================================
 * Carousel Styles
 * ========================================================================== */

@import '../utilities/variables';
@import '../vendor/bootstrap/scss/mixins';
@import '../vendor/bootstrap/scss/carousel';

.carousel {
  .carousel-control {
    opacity: 0.6;
    width: 20%;

    &:hover,
    &:active {
      opacity: 1;
    }

    &.right,
    &.left {
      background: none;
    }

    .left-control,
    .right-control {
      position: absolute;
      top: 50%;
      z-index: 5;
      display: inline-block;
      margin-top: -10px;
    }

    .left-control {
      left: 25%;
    }
    .right-control {
      right: 25%;
    }
  }
}
