.responsive-list-inline {
  @extend .list-inline !optional;
  .form-group {
    margin-bottom: 0px;
  }
  @include xs-only {
    > li {
      display: block;
    }
  }
}

ul.plainlist {
  margin-left: 0 !important;
  li {
    list-style-type: none;
  }
}

.aqua-list {
  li:before {
    content: '';
    background-color: #16a2bb;
    display: inline-block;
    position: relative;
    height: 6px;
    width: 6px;
    @include border-radius(6px);
    background-clip: padding-box;
    margin-right: 6px;
    top: -1px;
  }
}

.rover-list {
  list-style-type: none;

  li:not(.no-bullet):before {
    font-family: 'rover-icons';
    content: '\e630';
    margin: 0 5px 0 -20px;
    color: $primary-1;
  }
}

.rover-tiny-check-list {
  list-style-type: none;
  padding-left: 25px;

  li:before {
    font-family: 'rover-icons';
    content: '\e912';
    margin: 0 0 0 -25px;
    float: left;
    color: $primary-1;
  }
}

.numbered-list {
  counter-reset: rover-numbered-list;
  li {
    @extend .large-text;
    position: relative;
    list-style: none;
    padding: 0 0 25px 45px;

    &:before {
      @extend .small-text;
      @extend .heavy;
      color: $gray-4;
      position: absolute;
      top: -2px;
      left: 0;
      counter-increment: rover-numbered-list;
      content: counter(rover-numbered-list);
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 28px;
      border: 1px solid $gray-1;
      border-radius: 100%;
    }
  }
}
