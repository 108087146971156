@import '../base/colors';

.sitter-search-card {
  &.mobile {
    background-color: white;
    width: 100%;
    padding-bottom: 85%;
    position: relative;
    margin: 0 0 25px 0;
    box-shadow: 0 3px 0 #dddddd;

    &:first-of-type {
      margin-top: 10px;
    }

    & > .aspect-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      & > div {
        display: flex;
        flex-direction: column;
      }

      & > .body {
        justify-content: flex-end;
        width: 100%;
        height: calc(100% - 84px);
        color: white;
        background-size: cover;
        background-position: 50% 40%;
        background-color: #444;

        .price-overlay {
          background-color: $primary-1;
          padding: 5px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-top: none;
          border-right: none;
          position: absolute;
          top: 0;
          right: 0;

          & > .price {
            font-size: 18px;
          }
        }
      }

      & > .footer {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 84px;
        padding: 5px 10px;

        .info-column {
          padding: 0 15px;
          width: calc(100% - 90px);
          display: flex;
          flex-direction: column;
          justify-content: center;

          & > .row > div {
            padding-left: 8px;
            padding-right: 8px;
          }

          .name-column {
            display: flex;
            flex-direction: row;

            .name {
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
              color: #555;
              margin-right: 3px;
            }

            .review-stars {
              font-size: 12px;
              margin-right: 0;
              white-space: nowrap;

              & > i {
                margin-right: 1px;
              }
            }

            .reviews {
              position: relative;
              top: 3px;
              font-size: 12px;
            }
          }

          .location-column {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 0;

            & > span {
              font-size: 14px;
              color: #999;
            }
          }
        }

        .avatar-column {
          display: flex;
          align-items: center;

          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 4px;
            background-size: cover;
          }
        }

        .favorite-column {
          display: flex;
          align-items: center;

          & > .rover-btn-favorite {
            padding: 0;
            border: none;
            background: none;
            text-shadow: none;

            .rover-icon {
              color: $tertiary-2 !important;
              font-size: 30px;
            }
          }
        }

        & > .row > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #999;
        }
      }
    }
  }
}
