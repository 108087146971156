@import '../../../kibble/tokens/build/scss/tokens';

.about-us {
  &-hero-image {
    height: 200px;

    @include img-retina(
      '../images/about_us/about_resi_hero-mobile.jpg',
      '../images/about_us/about_resi_hero-mobile@2x.jpg',
      375px,
      200px
    );

    background-repeat: no-repeat;
    background-position: center 20%;
    background-size: cover !important;

    @media (min-width: $screen-sm-min) {
      height: 400px;

      @include img-retina(
        '../images/about_us/about_resi_hero.jpg',
        '../images/about_us/about_resi_hero@2x.jpg',
        1440px,
        400px
      );
    }
  }

  &-content-wrapper {
    background: $color-background-primary;
  }

  &-content {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 950px;

    @media (min-width: $screen-sm-min) {
      padding: 0px 98px;
      margin: 0 auto;
    }

    &--top-section {
      position: relative;
      top: -40px;
      background: $color-background-primary;
      @media (min-width: $screen-sm-min) {
        top: -180px;
        margin-bottom: -140px;
      }
    }

    &--contrast-section {
      margin-left: 0px;
      margin-right: 0px;
      padding: 32px 20px;
      background: $color-background-secondary;
      text-align: center;
      max-width: 100vw;

      h2 {
        margin-top: 0;
      }

      &__inner-wrapper {
        max-width: 950px;
        margin: 0 auto;
        @media (min-width: $screen-sm-min) {
          padding: 0px 98px;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
      line-height: 1.25;
    }

    h1 {
      font-size: 32px;
      margin-top: 0;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 24px;
      padding-top: 32px;

      @media (min-width: $screen-sm-min) {
        font-size: 40px;
        text-align: left;
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 48px;
      }
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 24px;

      b {
        font-family: $font-family-sans-serif;
        font-weight: 700;
      }
    }

    // links that aren't buttons
    a:not(.kibble-btn) {
      color: $color-blue-600;

      &:visited,
      &:hover {
        color: $color-blue-700;
      }
    }

    &__subtitle {
      font-family: $font-family-sans-serif;
      font-weight: 700;
      font-size: 20px;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 48px;

      @media (min-width: $screen-sm-min) {
        text-align: left !important;
        margin-bottom: 64px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    &__section-heading {
      font-family: Bogart-Semibold, Georgia, serif !important;
      font-weight: normal !important;
      font-size: 24px;
      margin-left: 0px;
      margin-top: 32px;
      margin-right: 0px;
      margin-bottom: 12px;

      @media (min-width: $screen-sm-min) {
        font-size: 32px;
        margin-top: 64px;
      }
    }

    &__left-align-header {
      text-align: left !important;
      font-size: 20px !important;
      margin-bottom: 16px;
    }

    &__stat-number {
      font-family: Averta-Extrabold, Avenir, Helvetica Neue, Helvetica, sans-serif;
      font-size: 28px;
      color: $color-green-700;

      @media (min-width: $screen-sm-min) {
        font-size: 32px;
      }
    }
    &__stat-label {
      color: $color-text-secondary;
      font-size: 13px;
      line-height: 125%;
      max-width: 194px;
      display: inline-block;
    }

    &__list {
      font-size: 16px;
      list-style-type: none;

      li {
        margin: 10px 0px !important;

        &:before {
          font-family: 'rover-icons';
          content: '\e912';
          font-size: 9px;
          margin: 0 11px 0 -20px;
          color: $color-green-600;
        }
      }
    }

    &__leader-wrapper {
      margin-top: 24px;
      margin-bottom: -48px;
    }
    &__leader-avatar {
      min-height: 250px;

      &__image {
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }

      &__label {
        font-size: 13px;
        color: $color-text-tertiary;
        text-transform: uppercase;
      }
    }
  }
}
