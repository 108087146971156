.table-large {
  th,
  td {
    font-size: $font-size-large;
    text-align: left;
    &.hilight {
      color: $brand-primary;
    }
    &.lolight {
      color: $gray-2;
    }
  }

  td {
    color: $text-muted;
    border: 0 !important;
  }
}
