@import '../utilities/variables';

.rover-slider {
  // Assuming use of horizontal sliders only
  .slider-horizontal {
    .slider-track {
      border-radius: 6px;
      background: rgba($gray-1, 0.5);
      box-shadow: none;
    }

    .slider-selection {
      background: $primary-1;
      box-shadow: none;
      height: 2px;
      margin-top: -1px;
      top: 50%;
    }

    .slider-handle {
      background: white;
      border: 1px solid $border-color;
      opacity: 1;

      @include xs-only {
        height: 28px;
        width: 28px;
        margin-left: -14px;
        margin-top: -8px;
      }

      &:hover,
      &:active {
        background: $primary-1;
        border-color: $primary-2;
        box-shadow: 0 0 0 4px rgba($primary-1, 0.3);
      }
    }
  }

  .tooltip {
    display: none;
  }
}
