.app-store-links {
  margin-bottom: 40px;

  .title {
    margin-bottom: 50px;
  }

  .showcase-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #ddd;

    .showcase-container > img {
      max-width: 400px;
    }
  }

  .subtitle {
    font-size: 16px;
    margin: 20px 0;
  }

  .itunes-column {
    display: flex;
    justify-content: flex-end;
  }
}

@include xs-only {
  .app-store-links {
    .title {
      margin-bottom: 20px;
    }

    .subtitle {
      margin-bottom: 27px;
    }

    .itunes-column {
      display: flex;
      justify-content: center;

      img {
        height: 50px;
      }
    }

    .google-column {
      display: flex;
      justify-content: center;

      img {
        height: 45px;
      }
    }

    .itunes-column {
      margin-bottom: 16px;
    }
  }
}
