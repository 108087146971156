/* ==========================================================================
 * Inbox Styles
 * ========================================================================== */

.inbox {
    .view-past-customers {
        text-decoration: none;
    }

    .inbox-pagination {
        border-bottom: 3px solid $border-color;
    }

    .empty-inbox-mobile {
        flex-direction: column;
        .empty-inbox-copy-mobile {
            padding: 16px;
        }
    }

    .empty-inbox {
        padding: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .empty-inbox-copy {
            padding-left: 40px;
    
            .empty-inbox-header {
                width: 320px;
                font-weight: 600;
                font-size: $font-size-large + 1; 
                line-height: 22px;
                color: #585F66;
            }
    
            .empty-inbox-text {
                width: 320px;
                font-weight: 400;
                font-size: $font-size-base;
                line-height: 20px;
                color: #7F8C9A;
            }
        }
    }

    .conversation {
        $conversation-padding: 20px;
        $conversation-min-height: 112px;

        // Standardize minimum height for visual uniformity
        min-height: $conversation-min-height;
        border-bottom: 1px solid $gray-lighter;

        @include responsive-spacing('padding-top', 4);
        @include responsive-spacing('padding-bottom', 4);

        &:hover {
            background-color: $background-color-lighter;
        }

        &:last-of-type {
            border: none;
        }

        &.read {
            .new-marker {
                display: none;
            }
        }

        .conversation-col-message {
            @include xs-only {
                @include responsive-spacing('margin-top', 2);
            }
        }

        .conversation-col-avatar .conversation-thumbnail {
            @include responsive-spacing('margin-right', 2);
            float: left;

            @include sm-only {
                @include responsive-spacing('margin-bottom', 2);
                margin-left: 0px;
                float: none;
            }
        }

        .conversation-alert {
            font-size: $font-size-small;
            color: $orange-700;
        }

        .conversation-col-actions {
            @include xs-only {
                @include responsive-spacing('margin-top', 4);
            }

            .conversation-action-wrap,
            .conversation-status-wrap {
                margin-bottom: 5px;
            }

            .conversation-action-wrap a {
                text-decoration: none;
            }
        }

        // Align archived reason edit button with text
        .edit-reason {
            vertical-align: baseline;
        }
    }

    .quick-match-callout {
        &:hover {
            background-color: inherit;
        }
    }

    table.dataTable thead th {

        /* Selectors are very specific to override the background image for data table's sorting. */
        &.sorting,
        &.sorting_desc,
        &.sorting_asc {
            white-space: nowrap;
            outline: none;
            position: relative;
            background: none;

            &>fieldset {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            .sort-btn {
                color: $neutral-600;
                display: block;
                position: absolute;
                right: 10px;
                padding: 0px;
                margin: 0px;
                border: 0px;
                background: transparent;
                font-size: 9px;
            }

            .sort-btn:disabled {
                color: $neutral-black;
                opacity: 1;
            }
        }
    }
}