.service-cards {
  padding-left: 10px;
  padding-right: 10px;

  .card {
    padding: 20px 30px;
    overflow: hidden;
    display: flex;

    & > i {
      font-size: 40px;
      color: $primary-1;
      padding-right: 15px;
      padding-top: 15px;
    }

    .content {
      .text-container {
        .title {
          white-space: nowrap;
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .action {
        min-width: 110px;
        margin-top: 4px;

        a {
          white-space: nowrap;
        }

        i {
          font-size: 24px;
          color: $secondary-1;
          position: relative;
          top: 5px;
          margin-left: 10px;
        }
      }
    }

    .corner-tag {
      font-size: 12px;
      background-color: $secondary-1;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      padding: 20px 25px 2px 25px;
      position: absolute;
      right: -26px;
      top: -9px;
      transform: rotate(45deg);
    }
  }

  @include md-only {
    .card-group > .card {
      padding: 20px 65px;
    }
  }

  @include media-breakpoint-up(md) {
    .card {
      i {
        font-size: 50px;
      }
    }

    .card-group {
      display: flex;

      .card {
        flex: 1;

        & > i {
          padding-right: 28px;
        }

        .content {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .text-container {
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .action {
            margin-top: 0;
            display: flex;
            align-items: center;

            & > i {
              position: inherit;
            }
          }
        }
      }
    }

    .loose-cards {
      display: flex;
      justify-content: space-between;

      & > .card {
        flex-direction: column;
        width: calc(33% - 10px);

        & > i {
          text-align: center;
          padding: 0;
          margin-bottom: 20px;
        }

        .content {
          height: 142px;

          .text-container {
            margin-bottom: 20px;

            .title,
            .message {
              text-align: center;
            }
          }

          .action {
            width: 100%;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
          }
        }
      }
    }
  }

  @include lg-only {
    display: flex;

    .card {
      padding: 20px 20px;
    }

    .card-group {
      width: 50%;
      margin-right: 20px;
    }

    .loose-cards {
      width: 50%;
    }
  }
}

.service-cards-row {
  & > .title {
    margin-bottom: 30px;

    @include xs-only {
      margin-bottom: 16px;
    }
  }
}
