.heading-check {
  margin-top: -4px;
}

.dual-tone-heading {
  @include table-row;
  margin: 0 auto;
  width: 100%;
  .left,
  .right {
    @include table-cell;
  }
  .left {
    background-color: $secondary-1;
    border-radius: 6px 0 0 6px;
    color: white;
  }
  .right {
    background-color: white;
    border: 2px solid $secondary-1;
    border-radius: 0 4px 4px 0;
    padding: 5px 20px;
    overflow: auto;
  }
  &.flat-bottom {
    .left {
      border-radius: 6px 0 0 0;
    }
    .right {
      border-radius: 0 4px 0 0;
    }
  }

  @media (max-width: $screen-sm-max) {
    .right {
      padding: 5px 10px;
    }
  }

  @include xs-only {
    .left,
    .right {
      float: left !important;
      display: block !important;
      background-color: $grey-light;
      color: black;
      border: none;
    }
  }
}

.heading-green {
  color: $primary-1;
}
