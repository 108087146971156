.premier-request {
  .premier-request-header {
    height: 350px;
    background-size: cover;
    background-position-x: 50%;
    background-color: $gray-dark;
    background-image: url('../images/premier/bg-premier-request@2x.jpg');
  }

  .premier-request-section,
  .premier-request-section-dark {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .premier-request-section {
    background-color: #fff;
  }

  .premier-request-section-dark {
    background-color: $gray-lighter;
  }

  .premier-request-how-it-works {
    .wizard-step-link.disabled {
      cursor: auto;
    }
  }

  .premier-request-features-img-container {
    max-width: 363px;
    @extend .center-block;

    @include xs-only {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  .premier-request-use-cases-header {
    margin-bottom: 30px;
  }

  .premier-request-content-block {
    @extend .text-center;
    @extend .margin-v-x6;
    padding-left: 40px;
    padding-right: 40px;

    @include sm-only {
      padding-left: 20px;
      padding-right: 20px;
    }

    .premier-request-help-img {
      height: 83px;
      margin-bottom: 10px;
    }
  }

  .premier-request-hours {
    margin-top: -45px;

    .premier-request-phone {
      background-color: $secondary-1;

      border-radius: $border-radius-base;

      @include xs-only {
        margin-left: -15px;
        margin-right: -15px;
      }

      .premier-request-phone-link a {
        color: #fff;
      }
    }
  }

  .staff-section {
    @extend .padding-x3;
    @extend .margin-v-x3;
    background-color: #fff;
  }

  .premier-request-confirmation {
    background-color: #fff;
  }
}
