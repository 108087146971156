/* ==========================================================================
 * Animation Utility Classes
 * ========================================================================== */

// Spin
.spin {
  display: inline-block;
  transform-origin: 50% 50%;
  animation: spin 0.2s infinite linear;
}

// Hatch
.hatch {
  animation: hatch 2s ease-in-out;
  transform-origin: 50% 100%;
  visibility: visible !important;
}

// Sliding entrances
.slideRight {
  animation: slideRight 0.3s ease-in-out;
  visibility: visible !important;
}

.slideLeft {
  animation: slideLeft 0.3s ease-in-out;
  visibility: visible !important;
}

// Expand Entrances
.expandDown {
  animation: expandDown 0.3s ease-in-out;
  overflow: hidden;
  visibility: visible !important;
}

// Highlight background
.highlight {
  animation: highlight 2s ease-out;
  transition: background-color 2s ease-out;
}

/* ==========================================================================
 * CSS Animation Definitions
 * ========================================================================= */

// Spin
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

// Hatch
@keyframes hatch {
  0% {
    transform: rotate(0deg) scaleY(0.6);
  }
  20% {
    transform: rotate(-2deg) scaleY(1.05);
  }
  35% {
    transform: rotate(2deg) scaleY(1);
  }
  50% {
    transform: rotate(-2deg);
  }
  65% {
    transform: rotate(1deg);
  }
  80% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// Sliding Entrances
@keyframes slideLeft {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes expandDown {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 1000px;
  }
}

@keyframes highlight {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(255, 255, 0, 0.5);
  }
  100% {
    background-color: transparent;
  }
}
