.rover-dropzone {
  @include responsive-spacing('padding', 5);
  cursor: pointer;
  text-align: center;
  border: 2px dashed #ccc;
  border-radius: $border-radius-base;
  display: table;
  width: 100%;
  height: 100%;

  .rover-dropzone-contents {
    display: table-cell;
    vertical-align: middle;

    .rover-dropzone-instructions {
      p {
        position: relative;
        top: -7px;

        i {
          position: relative;
          top: 14px;
        }
      }
    }
  }

  &.filehover {
    background-color: $gray-lighter;
    border-color: $gray-light;
  }
}

.native-dropzone {
  .rover-dropzone {
    padding: 0px;
    border: 0px;
  }
}
