@import '../components/promo_flag';

.dashboard {
  .profile-image-widget {
    .profile-image-column {
      @include sm-only {
        @include responsive-spacing('padding-bottom', 2);
      }
    }

    .profile-links-list {
      a { text-decoration: none; }
    }
  }
  .promo-wrapper {
    position: relative
  }

  .tax-information-cta {
    margin-top: $padding-base-vertical;

    @media (min-width: $screen-sm-min) {
      margin-top: 0px;
    }

    min-height: 78.2px;
    font-size: $font-size-large;

    .action-body {
        flex-direction: column;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: $padding-base-vertical;
        padding-right: $padding-base-vertical;
    
      @media (min-width: $screen-sm-min) {
        flex-direction: row;
        padding: 0px;
      }
    }
    
    .action-button {
      margin-left: 0px;
  
      @media (min-width: $screen-sm-min) {
        margin-left: $padding-large-horizontal;
      }


      a {
        text-wrap: nowrap;
        margin-top: $padding-base-vertical * 2;
      
        @media (min-width: $screen-sm-min) {
          margin-top: 0px;
        }
      }
    }
  }

  .promo-flag {
    position: absolute;

    @include sm-up {
      top: -35px;
      left: 5px;
    }
    @include xs-only {
      top: -22px;
      line-height: 16px;
      left: 5px;
    }
  }

  .account-sitter-resources {
    a { text-decoration: none }
  }
}

body.dashboard .popover {
  z-index: 1030 !important;
}
