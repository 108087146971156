.call-preferences {
  display: flex;
  flex-direction: column;
  min-height: 233px;
  justify-content: center;
  align-items: center;

  .phone-number-prompt {
    margin: 5px 0 15px 0;
    font-size: 2em;
    a {
      text-decoration: none;
    }
    .other-party-callable {
      color: $green-700;
    }
    .other-party-not-callable {
      color: $danger;
    }
  }
  .rover-loading-icon {
    display: block;
    margin: auto;
  }

  label,
  select {
    font-weight: normal;
  }
}
