/* ==========================================================================
 * Rover Tabs
 * ========================================================================== */

@mixin rover-pill-tabs($nav-theme: $primary-1) {
  padding: 10px 10px 0;
  list-style: none;
  border-radius: $border-radius-base;
  background: $nav-theme;
  margin: 0;

  &.flat-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  a {
    font-size: $font-size-base;
    font-weight: bold;
    color: white;
    display: block;
    background: rgba(white, 0.2);
    padding: 10px 20px;
    margin: 0 0 10px;
    border-radius: $border-radius-base;
    text-decoration: none;

    &:hover {
      color: white;
      background: darken($nav-theme, 5%);
      transition: background 200ms ease-in-out;
    }
  }

  & > li {
    font-size: 0;
    margin-right: 5px;
    display: inline-block;
    background: $nav-theme;
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    text-align: center;
    position: relative;

    &.active {
      background: white;

      a,
      a:hover {
        background: white;
        color: $text-color;
      }

      a:after {
        display: block;
        content: '';
        width: 40%;
        margin-left: -20%;
        height: 3px;
        border-radius: $border-radius-base;
        position: absolute;
        bottom: 10px;
        left: 50%;
        background: $nav-theme;
      }
    }
  }
}

.rover-pill-tabs {
  @include rover-pill-tabs();
}

@mixin rover-tabs($nav-theme: $primary-1) {
  $border-size: 1px;
  @include flexbox;
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    @include flex(1, 1, auto); // grow, shrink, basis
    position: relative;
    border: $border-size solid $border-color;

    > a {
      display: block;
      @include responsive-spacing('padding-top', 1);
      @include responsive-spacing('padding-bottom', 1);
      color: $text-muted;

      &:hover,
      &:active,
      &:focus {
        color: black;
        text-decoration: none;
      }
    }

    &:not(:last-child) {
      border-right: 0px;
    }

    &.active {
      border-bottom-color: transparent;

      // bar at top of active
      > a {
        color: black;

        &:after {
          position: absolute;
          top: - $border-size;
          left: 0;
          right: 0;
          height: 3px;
          background: $nav-theme;
          content: '';
        }
      }
    }
  }
}

.rover-tabs {
  @include rover-tabs();
}
