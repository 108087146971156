.service-landing {
  &-header {
    background-color: $gray-lighter;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover !important; //Unfortunately have to do this to override the retina mixin
  }

  &-devices {
    border-bottom: 3px solid $gray-lighter;
  }

  &-devices-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
