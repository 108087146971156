.conversation-images-section {
  .conversation-images-container-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100;
  }

  .conversation-images-container {
    @include responsive-spacing('padding-top', 2);
    @include responsive-spacing('padding-bottom', 2);
    @include responsive-spacing('padding-left', 0);
    @include responsive-spacing('padding-right', 0);
    font-size: 0; /* Prevent spacing between elements */
    height: 200px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  .conversation-image {
    display: inline-block;
    margin: 0;
    @include responsive-spacing('margin-right', 2);
  }
}
