.image-upload {
  .image-upload-form {
    border: 1px solid $border-color;
    @include border-radius(5px);
  }

  .image-type-header {
    @include border-top-radius(5px);
    border-bottom: 1px solid $border-color;
    background: $grey-light;
    overflow: auto;
    a:hover {
      text-decoration: none;
    }
  }

  .placeholder {
    width: 100px;
    height: 100px;
    text-align: center;
    background-image: linear-gradient(to right, $white, $grey-light);
    cursor: pointer;

    .fa {
      color: $grey;
      line-height: 100px;
      position: absolute;
      left: 0;
      width: 100%;
    }

    // Change to a plus icon on hover
    &:hover {
      .fa:before {
        content: '\f067';
      }
    }
    &.error {
      border-color: $brand-danger;
      color: $brand-danger;

      .fa {
        color: $brand-danger;
      }
    }
  }

  .provider-image {
    border: 1px solid $border-color;
    display: inline-block;
    margin: 0 10px 10px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100px;
    height: 100px;

    .editable-image {
      width: 100px;
      height: 100px;
      vertical-align: baseline;
    }

    .help-text {
      position: absolute;
      bottom: 0;
      display: block;
      text-align: center;
      color: $text-muted;
      width: 100%;
    }

    &:hover {
      .image-delete {
        display: block;
      }

      .image-title {
        color: $link-color;
        background: white;
      }
    }
  }

  .placeholder-extra {
    display: none;
  }

  .photo-container {
    overflow: auto;
  }

  .upload-image-link {
    font-size: 1.2em;
  }

  .section-help {
    display: none;
    margin-top: 45px;
  }

  .popover {
    max-width: none;
  }

  .image-delete,
  .image-title,
  .image-primary-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    font-size: 18px;
    line-height: 25px;
    border-radius: 2px;
  }

  .image-primary-icon {
    bottom: 1px;
    left: 1px;
    display: none;
    color: white;

    &:hover {
      color: $secondary-1;
    }
  }

  .image-delete {
    top: 1px;
    right: 1px;
    display: none;
    background: white;
  }

  .image-title {
    bottom: 1px;
    right: 1px;
    color: white;
  }

  .layered-comment {
    position: relative;

    .fa-ellipsis-h {
      position: absolute;
      top: 4px;
      left: 5px;
      font-size: 11px;
      color: $secondary-1;
    }
  }

  .drop-placeholder {
    border: 1px solid $border-color;
    display: inline-block;
    margin: 0 10px 10px;
    width: 100px;
    height: 100px;
  }

  // Only show the primary photo icon on the first image of the person photos section
  .person-photos {
    .provider-image:first-of-type {
      .image-primary-icon {
        display: block;
      }
    }
  }

  .image-title-dialog {
    .image-caption-wrap {
      //Ensures that the caption input field will take on the width of the image
      display: inline-block;
    }
  }
}
