@import '../utilities/mixins';

.bg-background-checks {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important; //Unfortunately have to do this to override the retina mixin

  // Desktop image
  background-image: url('../images/background_checks/new_hero.png');

  @include media-breakpoint-down(sm) {
    // Mobile image
    background-image: url('../images/background_checks/new_hero_m.png');
  }

}

#onfido-mount > .onfido-sdk-ui-Modal-inner {
  max-width: 100%;
}

@include xs-only() {
  #onfido-mount {
    .onfido-sdk-ui-Modal-inner {
      position: relative;
    }
    .onfido-sdk-ui-Theme-content > * {
      position: relative;
    }
    .onfido-sdk-ui-Theme-step {
      height: 90vh;
    }
  }
}

.trust-section {
  border: 2px solid #C9CFD4;
  border-radius: 8px;
  padding: 24px 16px 24px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

.bg-check-icon-display-xs {
  @include xs-only {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.bg-check-trust-logo {
  width: 50px;
}
