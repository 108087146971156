.button-as-link.flag-listing,
.attach-image-button {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.provider-card {
  .provider-name a {
    text-decoration: none;
  }
}

.rover-message-thread {
  display: block;
  overflow: hidden;

  .rover-message,
  .rover-message-thread-event {
    margin-bottom: 15px;
    clear: both;
  }

  .rover-message {
    @include md-up {
      width: 75%;
    }
  }

  // Make 'my' messages dark
  .mine {
    float: right;

    .rover-message-content-wrap {
      background-color: $background-color-light;
      border-color: $neutral-100;

      &:before {
        border-color: transparent $neutral-100;
      }

      &:after,
      .rover-message-sender {
        display: none;
      }
    }
  }
}

.new-design .booking-call-out {
  max-width: 520px;
  margin: auto;
}

.rover-primary-content-block {
  @include responsive-spacing('padding', 3);

  h3,
  h5 {
    margin-top: 0px;
  }

  .action-icon {
    width: 65px;
  }
  .action-icon > img {
    width: 100%;
    padding-right: 20px;
  }
}

.prewritten-response-fields {
  .chosen-default span {
    color: $text-muted;
  }
}

.message-video-wrap {
  .message-video-link {
    display: block;
    position: relative;

    &:hover {
      .message-video-play-icon {
        opacity: 0.9;
      }
    }

    .message-video-play-icon {
      opacity: 0.8;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  .modal-dialog {
    width: 640px;

    .modal-content {
      border-top: 0;

      .modal-body {
        padding: 0;
        overflow: visible;
      }
    }
  }
}

// TODO: Clean up when we've switched over to new conversation page.
.request-actions {
  text-align: center;

  .request-actions-action {
    @include responsive-spacing('margin-top', 1);

    &.link a,
    &.link .button-as-link {
      @include large-text;
      color: #fff;
      text-decoration: underline;
      line-height: 2em;
    }
  }
}

.system-message {
  margin-bottom: 15px;
  clear: both;
  padding: 10px;
  font-size: $font-size-base;
  position: relative;
  display: block;
  background-color: $background-color-lighter;
  border: 1px solid $border-color;
  border-radius: 4px;

  .system-message-icon {
    float: left;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .system-message-content {
    padding-left: 10px;

    .title {
      white-space: nowrap;
      font-size: 16px;
      font-weight: bold;
      color: $gray-5;
    }

    .message {
      color: $text-color-secondary;
      white-space: pre-line;
    }

    .action span {
      color: $text-color-secondary;
      margin-left: 10px;
      margin-right: 10px;
    }
    
    .flex-end{
      display: flex;
      justify-content: flex-end;
    }
  }

  .system-message-icon + .system-message-content {
    padding-left: 32px;
  }
}

.meet-greet-alert-container {
  margin-top: 15px;
}

.meet-greet-timezone-description {
  margin-bottom: 10px;
}

.unlock-rates-button {
  line-height: 1 !important;
}

.lock-rates-button {
  white-space: initial;
  text-align: left;
}

/* This class is used as a local correspondent to kibble text_100 */
.greybox-font-small {
  font-size: 13px;
}

/*--- Conversation Edit Charges Modal ---*/
.rover-modal {
  .js-pets-container {
    .pet-name {
      height: 31.2px;
    }
    .rover-icon-lg {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      height: 14px;
      margin-top: -7px;
      color: $danger;

      &.hidden {
        display: none;
      }
    }
  }
}
