/* ==========================================================================
 * New Services Rollout Styles
 * ========================================================================== */
.new-services-blocking-modal {
  .rover-icon {
    color: $primary-1;

    &:not(:first-child) {
      padding-left: 1.2em;
    }
  }
  .blocking-modal-content {
    padding: 0 10px;
  }
}
