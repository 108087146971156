//====================================================
//  Grid Componets
//====================================================

.flex-col {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: auto;
  flex-wrap: nowrap;

  @include small {
    min-width: 100%;
  }
}

.flex-col.center {
  // center the items across the column
  align-items: center;
}

.flex-row {
  display: flex;
  flex: 1 1 auto;
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-row > .flex-col {
  @include small {
    width: 100% !important;
  }
}

// ====================================================
//   Positioning for Flex Items
// ====================================================

// Columns & Rows need reversed styles to create the same effect

.flex-col.top-center {
  align-items: center;
  justify-content: flex-start;
}
.flex-col.top-right {
  align-items: flex-end;
  justify-content: flex-start;
}
.flex-col.center-left {
  align-items: flex-start;
  justify-content: center;
}
.flex-col.center-right {
  align-items: flex-end;
  justify-content: center;
}
.flex-col.bottom-left {
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-col.bottom-center {
  align-items: center;
  justify-content: flex-end;
}
.flex-col.center-center {
  align-items: center;
  justify-content: center;
}

.flex-row.top-center {
  align-items: flex-start;
  justify-content: center;
}
.flex-row.top-right {
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-row.center-left {
  align-items: center;
  justify-content: flex-start;
}
.flex-row.center-right {
  align-items: center;
  justify-content: flex-end;
}
.flex-row.bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}
.flex-row.bottom-center {
  align-items: flex-end;
  justify-content: center;
}
