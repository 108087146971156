.report-card {
  .route-image-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .activities {
    li .rover-icon {
      vertical-align: baseline;
    }
  }

  .report-card-summary {
    .row {
      // Styling for the rows
      &:nth-child(1) {
        > div {
          padding-bottom: 24px;
        }
      }
      &:nth-child(2) {
        > div {
          height: 47px;

          &:nth-child(2) {
            background-color: $primary-1;
            padding: 8px;
            .rover-icon {
              padding-top: 2px;
            }
          }
          &:nth-child(3) {
            margin-top: -2px;

            @include not-xs {
              @include responsive-spacing('padding-left', 5);
            }
          }
        }
      }
      &:nth-child(3) {
        > div {
          padding-top: 24px;
        }
      }

      // Styling for the columns
      > div {
        &:nth-child(1) {
          @include make-xs-column(2);
          @include responsive-spacing('margin-right', -6);
          @include xs-only {
            @include responsive-spacing('margin-right', -3);
          }
          color: $text-muted;
        }
        &:nth-child(2) {
          @include make-sm-column(2);
          @include make-xs-column(3);
          @include lg-only {
            @include responsive-spacing('margin-left', -8);
          }
          @include md-only {
            @include responsive-spacing('margin-left', -4);
          }
          @include sm-only {
            @include responsive-spacing('margin-left', -4);
          }
          max-width: 47px;
          text-align: center;
          color: $primary-1;
        }
        &:nth-child(3) {
          @include make-sm-column(8);
          @include make-xs-column(7);
        }
      }
    }

    .with-vertical-line-above,
    .with-vertical-line-below {
      &::after {
        content: '';
        display: block;
        border-left: 1px solid;
        height: 50px;
        position: absolute;
        right: 50%;
      }
    }
    .with-vertical-line-below {
    }
    .with-vertical-line-above {
      &::after {
        bottom: 10%;
      }
    }
  }
}
