@import '../../../kibble/tokens/build/scss/tokens';

.id-verified {
  &-hero-image {
    height: 200px;

    @include img-retina(
      '../images/id_verify/ID_Badge_Verified_Hero_mobile.jpg',
      '../images/id_verify/ID_Badge_Verified_Hero_mobile@2x.jpg',
      375px,
      200px
    );


    background-repeat: no-repeat;
    background-position: center 20%;
    background-size: cover !important;

    @media (min-width: $screen-sm-min) {
      height: 400px;

      @include img-retina(
        '../images/id_verify/ID_Badge_Verified_Hero.jpg',
        '../images/id_verify/ID_Badge_Verified_Hero@2x.jpg',
        1440px,
        400px
      );
    }
  }

  &-content-wrapper {
    background: $color-background-primary;
  }

  &-content {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 950px;

    @media (min-width: $screen-sm-min) {
      padding: 0px 98px;
      margin: 0 auto;
    }

    &--top-section {
      position: relative;
      top: -40px;
      background: $color-background-primary;
      @media (min-width: $screen-sm-min) {
        top: -180px;
        margin-bottom: -140px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
      line-height: 1.25;
    }

    h1 {
      font-size: 32px;
      margin-top: 0;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 24px;
      padding-top: 32px;

      @media (min-width: $screen-sm-min) {
        font-size: 40px;
        text-align: left;
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 48px;
      }
    }

    .no-white-space{
      margin-bottom: 0px !important;
    }
    
    .center-content{
        display: flex;
        justify-content: center;
    }

    .badge-img{
        @media (max-width: $screen-sm-min) {
            width: 20%;
            height: 20%;
        }
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 24px;

      b {
        font-family: $font-family-sans-serif;
        font-weight: 700;
      }
    }

    // links that aren't buttons
    a:not(.kibble-btn) {
      color: $color-blue-600;

      &:visited,
      &:hover {
        color: $color-blue-700;
      }
    }

    &__list {
      font-size: 16px;
      list-style-type: none;

      li {
        margin: 10px 0px !important;

        &:before {
          font-family: 'rover-icons';
          content: '\e912';
          font-size: 9px;
          margin: 0 11px 0 -20px;
          color: $color-green-600;
        }
      }
    } 
  }
}
