.pet-wrap {
  $height: 186px;
  position: relative;
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;
  overflow: hidden;
  height: $height;

  // Background image url supplied inline by server
  background: $gray-dark no-repeat center center;
  background-size: cover;
  color: blue;

  .pet-info-wrap,
  .pet-info {
    @include responsive-spacing('padding', 4);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: $text-color-tertiary-inverse;

    .text-muted {
      color: $text-color-tertiary-inverse;
    }

    h3,
    h4 {
      color: $text-color-link-inverse;
    }
  }

  .pet-info-wrap {
    height: $height;
    background-image: linear-gradient(
      to bottom,
      transparent,
      transparent 30%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.9)
    );
    top: 0;
  }
}

.pet-wrap-round {
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;
  border-bottom-left-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;
}

.pet-links-wrap {
  @include responsive-spacing('padding-left', 3);
  @include responsive-spacing('padding-right', 3);
  @include responsive-spacing('padding-top', 1);
  @include responsive-spacing('padding-bottom', 1);
  @include h4;
}

.pet-links-list {
  @include responsive-spacing('margin-top', 1);
  @include responsive-spacing('margin-bottom', 1);

  li {
    @include h5;
    @include responsive-spacing('padding-right', 3);

    a {
      color: $text-color-link;
      text-decoration: none;
      font-weight: 400;
    }

    i {
      @include responsive-spacing('padding-right', 2);
    }
  }
}

a.account-profile-buttons-add-pet {
  text-decoration: none;
}

.add-pet-wrap {
  position: relative;
  height: 232px;
  text-align: center;
  border: 1px dashed $gray-light;
  border-radius: $border-radius-base;

  @include xs-only {
    height: 120px;
    @include full-bleed;

    // Since the block is full-bleed on small screens,
    // child rows cannot have negative margins
    margin-right: 0;
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
  }

  .add-pet-content {
    @include vertically-align;
  }
}
