/* ==========================================================================
 * Background Color Utilities
 * ========================================================================== */

.bg-very-dark {
  background-color: $background-color-dark;
}

.body-dark,
.bg-dark {
  background-color: $background-color-light !important;
}

.bg-lighter {
  background-color: $background-color-lighter;
}

.bg-white {
  background-color: $white;
}

.bg-primary {
  background-color: $primary-1;
}

.bg-none {
  background-color: transparent !important;
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.bg-transparent-a50 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.with-shadow {
  box-shadow: 0 3px rgba(0, 0, 0, 0.1);
}

.no-shadow {
  box-shadow: none !important;
}
