/* ==========================================================================
 * Pagination For Multipage Lists
 * ========================================================================== */

.rover-pagination {
  $pagination-edges: 12px;

  display: inline-block;
  margin: 10px 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 40px;
  list-style: none;
  text-align: center;
  font-size: 0;
  background-color: white;

  & > li {
    display: inline-block;
    border-right: 1px solid #eee;
    font-size: $font-size-base;

    & > a,
    & > i,
    & > span {
      position: relative;
      display: block;
      padding: 9px 10px;
      color: $gray-4;
      text-decoration: none !important;
      line-height: 1em;
    }

    // Tweak padding for start and end pagination controls
    &:first-child {
      & > a,
      & > span {
        padding-left: $pagination-edges;
      }
    }
    &:last-child {
      border-right: 0;

      & > a,
      & > span {
        padding-right: $pagination-edges;
      }
    }

    // Hover state
    a:hover {
      color: $link-color;
    }

    // Active state
    &.active {
      & > a,
      & > span {
        color: $text-color-primary;
        font-weight: bold;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: $primary-1;
          content: '';
        }
      }
    }

    // Disabled state
    &.disabled {
      & > a,
      & > span {
        opacity: 0.25;
      }
    }
  }
}
