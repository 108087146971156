.profile-backfill-container {
  .profile-backfill-header {
    @include sm-up {
      br {
        display: none;
      }
    }
  }
  .profile-backfill-questions-container {
    .profile-backfill-question:first-of-type {
      display: block;
    }
    .profile-backfill-question {
      display: none;
    }
    select,
    input[type='text'],
    input[type='number'],
    input[type='email'] {
      width: 200px;
    }
    .asteriskField {
      display: none;
    }
    #div_id_max_dogs {
      display: none;
    }
  }
  .profile-backfill-waiting {
    font-size: 25px;
    margin-top: -30px;
  }
  #div_id_accepts_uncrated_dogs {
    .controls {
      padding-top: 5px;
      label {
        display: block;
        margin-left: 3px;
      }
    }
  }
}
