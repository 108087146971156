// Pull encircled icons inside rover-wells
.rover-well.dark {
  h4 {
    position: relative;
  }
  .icon-encircled {
    position: absolute;
    left: -40px;
    top: -5px;
  }
}

// prevent horizontal scrolling
body.checkout {
  .rover-main-content-wrap {
    overflow-x: hidden;
  }
}

$cancellationMessageBgColor: rgba(255, 255, 0, 0.5);

@keyframes animateToNoBackground {
  0% {
    background-color: $cancellationMessageBgColor;
  }
  100% {
    background-color: transparent;
  }
}

.cancellation-message {
  display: inline-block;
  background-color: $cancellationMessageBgColor;
  &.remove-highlight {
    animation: animateToNoBackground 2s ease-out;
    animation-fill-mode: forwards;
    transition: background-color 2s ease-out;
  }
  .rover-icon-in-check-mark {
    float: left;
    color: $primary-1;
    margin: 2px 5px 0 0;
    + strong {
      display: block;
      overflow: hidden;
    }
  }
}

.new-checkout {
  .rover-primary-content-block {
    box-shadow: none;
    margin-bottom: $size-space-24-x;
    padding-bottom: $size-space-0-x;
    padding: $size-space-6-x;
  }

  .checkout-header-margin-bottom-6x {
    margin-bottom: $size-space-6-x;
  }

  .checkout-header-title-margin-top-3x {
    margin-top: $size-space-4-x;
  }

  .button-as-link {
    text-decoration: none;
  }

  .heading-200 {
    font-family: $typography-heading-200-font-family;
    font-size: $typography-heading-200-font-size;
    font-weight: $typography-heading-200-font-weight;
    line-height: $typography-heading-200-line-height;
  }

  .heading-300 {
    font-family: $typography-heading-300-font-family;
    font-size: $typography-heading-300-font-size;
    font-weight: $typography-heading-300-font-weight;
    line-height: $typography-heading-300-line-height;
  }

  .text-200-semibold {
    font-family: $typography-text-200-font-family;
    font-size: $typography-text-200-font-size;
    font-weight: $weight-font-semibold;
    line-height: $typography-text-200-line-height;
  }

  .text-300 {
    font-family: $typography-text-300-font-family;
    font-size: $typography-text-300-font-size;
    font-weight: $typography-text-300-font-weight;
    line-height: $typography-text-300-line-height;
  }

  #react-ccform {
    > div > div.form-group {
      margin-bottom: $size-space-4-x;
      min-height: 136px;
    }
  }
  .sitter-card {
    &.mobile {
      display: none;
      @include xs-only {
        display: inline;
        padding: 0px;
        .provider-card {
          margin: 32px 0px;
          padding: 8px 0px;
          .provider-name {
            font-size: $typography-heading-200-font-size;
          }
          .provider-neighborhood {
            font-size: $typography-heading-100-font-size;
          }
        }
      }
    }
    &.desktop {
      display: inline;
      .rover-widget-block {
        box-shadow: none;
        margin-bottom: 0px;
        margin-left: 10px;
        .provider-card {
          .provider-name {
            font-size: $typography-heading-200-font-size;
          }
          .provider-neighborhood {
            font-size: $typography-heading-100-font-size;
          }
        }
      }
      @include xs-only {
        display: none;
      }
    }
  }

  .conversation-summary {
    margin: $size-space-0-x;

    &.mobile {
      display: none;
      .rover-widget-block-inner {
        display: none;
      }
      @include xs-only {
        display: inline;

        .rover-widget-block-inner {
          display: block;
          padding: $size-space-2-x $size-space-0-x $size-space-4-x
            $size-space-0-x;
        }
      }
    }

    &.desktop {
      display: inline;

      .rover-widget-block-inner {
        display: block;
        padding-top: $size-space-2-x;
      }

      @include xs-only {
        display: none;
        .rover-widget-block-inner {
          display: none;
        }
      }
    }
  }

  .payment-promo-section {
    display: flex;
    flex-direction: column;
    @include xs-only {
      flex-direction: column-reverse;
    }
  }

  .rover-icon-calendar {
    height: $size-space-6-x;
    max-width: $size-space-6-x;
    margin-right: $size-space-4-x;
    padding: $size-space-0-x;
  }

  .rover-icon-secure {
    height: $size-space-6-x;
    max-width: $size-space-6-x;
    vertical-align: baseline;
  }

  .rover-icon-stripe {
    height: 30px;
    vertical-align: baseline;
  }

  .kibble-margin-top-x2 {
    margin-top: $size-space-2-x;
  }

  .kibble-margin-bottom-x2 {
    margin-bottom: $size-space-2-x;
  }

  .kibble-margin-bottom-x4 {
    margin-bottom: $size-space-4-x;
  }

  .kibble-margin-top-x4 {
    margin-top: $size-space-4-x;
  }

  .kibble-margin-bottom-x6 {
    margin-bottom: $size-space-6-x;
  }

  .kibble-margin-top-x6 {
    margin-top: $size-space-6-x;
  }

  .kibble-margin-bottom-x8 {
    margin-bottom: $size-space-8-x;
  }

  .kibble-margin-top-x12 {
    margin-top: $size-space-12-x;
  }

  .kibble-margin-bottom-x12 {
    margin-bottom: $size-space-12-x;
  }

  .kibble-padding-h-x3 {
    padding-right: $size-space-4-x;
    padding-left: $size-space-4-x;
  }
  .kibble-padding-top-x8 {
    padding-top: $size-space-8-x;
  }
  .media-body-margin-top10 {
    margin-top: 10px;
  }
  .kibble-inline {
    display: flex;
    flex-direction: row;
  }
  .kibble-flex-end {
    display: flex;
    justify-content: flex-end;
  }
}
