// These are all overrides for the fullcalendar default style

.rover-full-calendar.fc {
  .fc-button {
    background-color: transparent;
    background-image: none;
    border: none;
    text-shadow: none;
    box-shadow: none;
    color: $text-color;
    cursor: pointer;
    display: inline;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: darken($text-color, 10%);
      &:disabled,
      &.disabled {
        text-decoration: none;
      }
    }
    &:disabled,
    &.disabled {
      color: $text-muted;
      cursor: default;
    }
    &:active,
    &:focus {
      background-image: none;
    }
  }

  .fc-button-group > *,
  .fc-toolbar > * > * {
    float: none;
  }

  .fc-toolbar {
    margin-bottom: 0;
  }

  .fc-title {
    text-align: left;
    width: 60%;
  }

  .fc-buttons {
    text-align: right;
    width: 40%;
  }

  .fc-title,
  .fc-buttons {
    float: left;
    vertical-align: middle;
  }

  .fc-button .fc-icon {
    display: inline;
    top: 0;
    margin: 0;
    width: auto;
    height: auto;
    font-size: 2em;
  }

  .fc-title h2 {
    @include h4;
    text-transform: uppercase;
    margin-top: 5px;
  }

  .fc-day-header,
  .fc-widget-content {
    text-align: center;
    vertical-align: middle;
    border-width: 0;
  }

  .fc-day-header {
    background-color: white;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: $font-size-small;
  }

  .fc-day-grid {
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-right-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
  }

  .fc-view {
    table {
      border-collapse: separate;
    }
  }

  .fc-widget-header {
    background-color: white;
    border-top-right-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    border-color: #ccc;
    font-weight: normal;
  }

  .fc-day-number {
    text-align: center;
    vertical-align: middle;
    &.hoverable:hover {
      background-color: darken($primary-1, 10%);
    }
    color: $text-muted;
    &.rover-has-event {
      color: white;
      background-color: $background-color-primary-with-white-text;
      font-family: $font-family-sans-serif;
      font-weight: 800;
      -webkit-font-smoothing: antialiased;
    }
  }

  .fc-day {
    background-color: lighten($gray-lighter, 3%);
  }

  .fc-day-number.fc-other-month {
    opacity: 1; // override default FC styling which applies opacity to background color too
    color: rgba(255, 255, 255, 0.3); // lighten text for "disabled" calendar days
  }

  .last-sat {
    border-bottom-right-radius: $border-radius-base;
  }

  .last-sun {
    border-bottom-left-radius: $border-radius-base;
  }

  .fc-bgevent {
    background-color: $primary-1;
    opacity: 1;
  }

  .fc-row .fc-content-skeleton {
    bottom: 0;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    table {
      height: 100%;
    }
  }

  // These navigation arrows need custom horizontal padding to
  // make them big enough click targets on phones.
  .fc-next-button,
  .fc-prev-button {
    $mobile-h-spacing: 8px;
    $desktop-h-spacing: 5px;
    height: auto;
    position: relative;
    right: -$mobile-h-spacing;
    padding: 5px $mobile-h-spacing 10px;

    @include sm-up {
      padding-left: $desktop-h-spacing;
      padding-right: $desktop-h-spacing;
      right: -$desktop-h-spacing;
    }
  }
}

.calendar-avail-popover {
  .service-listing {
    @include responsive-spacing('margin-bottom', 3);
  }

  .service-listing:last-of-type {
    margin-bottom: 0;
  }
}

.edit-availability-modal {
  @include not-xs {
    .btn + .btn {
      margin-left: 5px;
      margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }
  }

  @include xs-only {
    .btn + .btn {
      margin-top: 5px;
      margin-left: 0px;
      margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }
  }
}
