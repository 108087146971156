.youtube-modal {
  .vertical-alignment-helper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    button.close {
      position: absolute;
      top: 15px;
      right: 20px;
      color: white;
      z-index: 100;
      padding: 10px;
    }

    .modal-dialog {
      width: 100%;
      max-width: 1920px;
      margin: 0;

      .modal-content {
        border: 0;

        .modal-body {
          position: relative;
          padding-bottom: 55%;
          padding-top: 25px;
          height: 0;
          background-color: black;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
