.premium-insurance-page {
  .insurance-subtitle {
    line-height: 1.6;
  }
  .rover-list,
  p {
    font-size: 120%;
    line-height: 1.6;
  }
}
