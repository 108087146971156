.calendar-page {
  .confirm-calendar-message {
    display: block;
    margin-bottom: 10px;
  }

  .calendar-confirmed-availability {
    @include xs-only {
      padding-top: 20px;
    }
    @include not-xs {
      padding-top: 3px;
    }
  }

  @include sm-up {
    .rover-primary-content-block {
      padding: 0px;
    }
  }
  @include xs-only {
    .footer {
      display: none;
    }
  }
  .fc {
    touch-action: none;
    -ms-touch-action: none;
  }
  .fc-today-button {
    padding: 8px 16px;
    text-transform: capitalize;
    line-height: 1.6;
    height: auto;
    @include xs-only {
      display: none;
    }
  }
  .fc-prev-button,
  .fc-next-button {
    color: $gray-3;
    height: auto;
    background: transparent;
    border: none;
    border-radius: 50%; // make focus outline round to match button
    padding: 6px;
  }
  .calendar-dot {
    $height: 10px;
    margin: 0 2px;
    display: inline-block;
    height: $height;
    width: $height;

    &.default-unavailable {
      background-color: rgb(218, 77, 24);
      border-radius: $height;
    }

    &.default-available {
      background-color: rgb(0, 189, 112);
      border-radius: $height;
    }

    // Service type legend "dots" need to be different shapes as well as colors to be accessible to colorblind users
    &.service-type-1 {
      // red square
      background-color: #dd624a;
    }
    &.service-type-2 {
      // orange circle
      position: relative;
      width: $height + 2px;
      height: $height + 2px;
      margin-right: 0;
      left: -2px;
      background-color: #f49b32;
      border-radius: 50%;
    }
    &.service-type-3 {
      // yellow downward-pointing triangle
      $border-width: $height - 3px;
      position: relative;
      left: -1px;
      width: 0;
      height: 0;
      margin: 0;
      border-left: $border-width solid transparent;
      border-right: $border-width solid transparent;
      border-top: $border-width * 2 solid #fcd34b;
    }
    &.service-type-4 {
      // green diamond
      $border-width: $height - 3px;
      position: relative;
      top: -$border-width;
      left: -1px;
      width: 0;
      height: 0;
      margin: 0;
      border: $border-width solid transparent;
      border-bottom-color: #3dc085;
      &:after {
        content: '';
        position: absolute;
        left: -$border-width;
        top: $border-width;
        width: 0;
        height: 0;
        border: $border-width solid transparent;
        border-top-color: #3dc085;
      }
    }
    &.service-type-5 {
      // blue upward-pointing triangle
      $border-width: $height - 3px;
      position: relative;
      left: -1px;
      width: 0;
      height: 0;
      margin: 0;
      border-left: $border-width solid transparent;
      border-right: $border-width solid transparent;
      border-bottom: $border-width * 2 solid #138282;
    }
    &.service-type-6 {
      // purple empty square
      box-sizing: border-box;
      border: 3px solid #4a1382;
    }
    &.service-type-7 {
      // solid pink pentagon for training
      background: #D13880;
      position: relative;
      left: -1px;
      top: 1px;
      margin-right: 0px;
      height: $height + 2px;
      width: $height + 2px;
      clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
    }
  }
  .fc-toolbar {
    margin-top: 2em;
  }
  .fc-today {
    background: inherit;
  }
  .fc-day-number {
    text-align: left !important;
    padding: 5px !important;

    @include xs-only {
      text-align: center !important;
    }
  }
  .fc-state-active,
  .fc-state-down {
    box-shadow: none;
  }
  .fc-event {
    &:hover {
      color: inherit;
    }
  }
  .fc-day-header {
    text-transform: uppercase;
    line-height: 2em;
    border-color: transparent;
  }
  .fc-body {
    cursor: pointer;
  }
  .rover-mobile-availability-event {
    display: none;
    text-align: center;

    @include xs-only {
      display: block;
      .calendar-dot {
        margin: 0;
        margin-right: -5px;
      }
    }
  }
  .text-muted {
    .fc-title {
      color: #999999 !important;
    }
  }
  .rover-availability-event {
    .fc-title {
      .fc-units {
        @include sm-only {
          display: none;
        }
      }
    }

    @include xs-only {
      display: none;
    }
  }

  .fc-content {
    white-space: normal  !important;
  }
  // Work around fullcalendar adding an fc-scroller when all heights are "auto"
  .fc-scroller {
    overflow-y: auto !important;
    overflow-x: auto !important;
    height: auto !important;
  }

  .calendar-list {
    .calendar-info {
      @include h4;
      font-family: 'rover-icons';
      vertical-align: text-bottom;
    }

    .loading-space {
      height: 100px;
    }
  }
  .calendar-sidebar {
    .sidebar-spacing {
      margin-top: 84px;
    }
    h6 {
      @include responsive-spacing('margin-top', 8);
    }

    .link-list a {
      @include responsive-spacing('margin-bottom', 2);
      display: block;
    }
  }

  .sync-your-calendar {
    white-space: nowrap;
  }

  .calendar-listing {
    .calendar-arrow {
      @include responsive-spacing('margin-right', -1);
    }
    .calendar-dropdown {
      @include responsive-spacing('padding', 1);
      width: 90%;
      cursor: pointer;
      background: none;
      border: none;
      text-align: left;
    }
    .calendar-name {
      display: inline-block;
    }
    .calendar-toggle {
      @include responsive-spacing('margin-left', -1);
      @include responsive-spacing('margin-right', 1);
      @include responsive-spacing('padding', 1);

      cursor: pointer;

      .calendar-dot.inactive {
        background: white !important;
      }
    }
    .calendar-options {
      @include responsive-spacing('padding-left', 5);
    }
  }

  .edit-availability-row {
    @include responsive-spacing('padding-top', 3);
    @include responsive-spacing('padding-bottom', 3);

    border-bottom: 1px solid $border-color;
    line-height: 2em;

    .calendar-name {
      font-weight: bold;
    }

    .adjuster {
      border: none;
      border-radius: 50%;
      background: none;
      cursor: pointer;
      font-weight: 400;
      font-size: 1.5em;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
