.rover-well {
  @extend .well;
  border: none;
  box-shadow: none;
  &.inverse {
    border: 1px solid $border-color;
    background-color: transparent;
  }
  &.dark {
    background-color: $gray;
    color: white;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: white;
    }
    a:hover {
      color: $secondary-1;
    }
  }
  &.squared {
    border-radius: 0;
  }
}
