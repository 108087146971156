/* ==========================================================================
 * Content Block
 * ==========================================================================
 * Groups large blocks of content visually on the page
 */

@import '../../../kibble/tokens/build/scss/tokens';

@mixin rover-content-block {
  @include xs-only {
    @include full-bleed;

    // Since the block is full-bleed on small screens,
    // child rows cannot have negative margins
    & > .row {
      margin-right: 0;
      margin-left: 0;
    }
  }
  background: white;
  box-shadow: 0 3px 0 $gray-1;
  @include responsive-spacing('margin-bottom', 4);

  @include sm-up {
    border-radius: $border-radius-base;
  }
}

.rover-content-block {
  @include rover-content-block;
}

/* Main Content block
 * =========================================================================
 * Our main content block has significantly more padding on large screens
 * and supports full-bleed inner content
 */
@mixin rover-primary-content-block($inner-padding) {
  padding: $inner-padding;

  @include sm-up {
    padding: $inner-padding * 2;
  }

  .full-bleed-xs {
    @include xs-only {
      margin-left: $inner-padding * -1;
      margin-right: $inner-padding * -1;
      padding-right: $inner-padding;
      padding-left: $inner-padding;
    }
  }

  .full-bleed {
    margin-left: $inner-padding * -1;
    margin-right: $inner-padding * -1;
    padding-right: $inner-padding;
    padding-left: $inner-padding;

    @include sm-up {
      margin-left: $inner-padding * 2 * -1;
      margin-right: $inner-padding * 2 * -1;
      padding-right: $inner-padding * 2;
      padding-left: $inner-padding * 2;
    }
  }
}

.rover-primary-content-block {
  @include rover-content-block;
  @include rover-primary-content-block(20px);

  &.lo-pad {
    @include rover-primary-content-block(10px);
  }
}

.rover-priority-block {
  @include rover-content-block;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;

  &.lo-pad {
    @include rover-primary-content-block(5px);
  }
}


/* Rover Widget
 * ======================================================================== */
.rover-widget-block {
  @include rover-content-block;

  & > .img-full-width {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
  }

  .rover-widget-block-inner {
    @include responsive-spacing('padding', 4);
    @include clearfix;
  }

  .rover-widget-title {
    margin-top: 0;
    @include responsive-spacing('margin-bottom', 2);
  }

  .rover-widget-body {
    @include responsive-spacing('margin-bottom', 3);
  }

  .rover-widget-block-inner-wide {
    @include responsive-spacing('padding', 0);
    @include clearfix;

    .rover-widget-title {
      @include responsive-spacing('padding-left', 4);
      @include responsive-spacing('padding-right', 4);
      @include responsive-spacing('padding-top', 3);
      @include responsive-spacing('padding-bottom', 3);
    }

    .rover-widget-body {
      @include responsive-spacing('min-height', 30);
    }
  }
}

.no-box {
  box-shadow: none;
  margin-bottom: 15px;
}

.rover-section-widget {
  $icon-size: 40px;
  position: relative;
  @include responsive-spacing('padding', 2);
  @include responsive-spacing('margin-bottom', 4);

  @mixin section-widget-with-icon() {
    margin-top: calc($icon-size/2);
  }

  &.has-icon {
    @include section-widget-with-icon;
  }

  .list-attribute {
    color: $gray-5;
    font-size: 16px;
    font-weight: 400;
    margin: 0;

    .icon {
      position: relative;
      top: -2px;
      font-size: 20px;
    }
  }

  .widget-icon {
    display: none;
  }

  @mixin section-widget-with-border-and-icon() {
    border-radius: $border-radius-base;
    border: 1px solid $border-color;

    .widget-icon {
      font-size: 24px;
      display: block;
      position: absolute;
      top: calc($icon-size / -2);
      right: 7px;
      width: $icon-size;
      height: $icon-size;
      padding-top: 9px;
      color: $color-text-primary;

      .rover-icon {
        position: relative;
        top: -7px;
      }
    }

    .widget-icon-photos {
      background-color: $cyan-100;
    }

    .widget-icon-repeat {
      background-color: $green-100;
      justify-content: center;
      display: flex;
    }

    .widget-icon-experience {
      background-color: $yellow-200;
    }
  }

  @include sm-up {
    @include section-widget-with-border-and-icon;
    float: left;
    margin-right: 5px;
  }

  @include lg-only {
    width: 100%;
    min-height: inherit;
    margin-right: 0;
    margin-bottom: calc($icon-size/2 + 10px);
  }

  &.reviews-widget {
    @include section-widget-with-icon;
    min-height: 75px;

    @include xs-only {
      @include section-widget-with-border-and-icon;
      display: inline-block;
      vertical-align: top;
      min-height: 68px;
    }
  }
}
