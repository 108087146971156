/* ==========================================================================
 * Simple Horizontal Nav
 * ========================================================================== */

.rover-flat-nav {
  @include clearfix;
  background: white;
  text-align: center;
  border-bottom: 3px solid $border-color;

  .styleguide-header & {
    border-bottom: 0;
    text-align: left;
  }

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      font-size: 0;
      display: inline-block;

      // First first child margin due to inline-block
      &:first-of-type {
        position: relative;
        margin-left: -4px;
      }
    }

    a {
      font-size: $font-size-base;
      color: $text-color-primary;
      display: inline-block;
      padding: 20px 10px 15px;
      text-decoration: none;

      &:after {
        content: '';
        display: none;
        bottom: -3px;
        height: 3px;
        position: absolute;
        left: 0;
        margin-left: 0;
        width: 100%;
        background: $border-color-secondary;
      }

      &:hover {
        background: $background-color-lighter;
        &:after {
          background: $border-color-primary;
        }
      }
    }

    .active {
      a {
        &:after {
          background: $interactive-color-default;
          display: block;
        }
      }
    }
  }

  // Height fix for bootstrap badges in nav
  .badge {
    padding: 2px 7px;
  }
}
