.section-break {
  display: block;
  height: 3px;
  border: 0;
  border-top: 3px solid $primary-1;
  width: 100%;

  margin: 0;

  &.w-25 {
    width: 25%;
  }

  &.w-50 {
    width: 50%;
  }

  &.w-75 {
    width: 75%;
  }
}
