.img-circle-with-border {
  @extend .img-circle;
  border: 4px solid $secondary-1;
}

.img-responsive {
  @include img-responsive();
}

.img-responsive-max-width-200 {
  @include img-responsive();
  max-width: 200px;
  margin: 0 auto;
}

.img-full-width {
  @include img-responsive();
  width: 100%;
}

.rover-thumbnail {
  @extend .thumbnail;
  border-radius: 0;
  padding: 0;
}

// Hides marketing pixels
img[height="1"][width="1"], img[height="0"][width="0"] {
  display: none;
}
