/* ==========================================================================
 * Block of text with rounded corners and colored background
 * ========================================================================== */

@mixin text-badge($bg-color, $text-color) {
  @include small-text;
  display: inline-block;
  border-radius: $border-radius-base;
  color: $text-color;
  background: $bg-color;
  padding: 2px 6px;
  text-transform: uppercase;
}

.rover-text-badge {
  @include text-badge($brand-primary, white);
  &.rover-text-badge-secondary {
    background: $secondary-1;
  }
  &.rover-text-badge-tertiary {
    background: $tertiary-2;
  }
  &.rover-text-badge-muted {
    background: $background-color-lighter;
    color: $text-muted;
  }
  &.rover-text-badge-dark {
    background: $gray;
    color: white;
  }
}
