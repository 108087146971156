/* ==========================================================================
 * Rover Content Section
 * ========================================================================== */

@mixin rover-content-section($border: true) {
  @if $border {
    border-top: 1px solid $border-color;
  } @else {
    border-top: none;
  }
  @include responsive-spacing('margin-top', 8);

  .rover-content-section-title {
    display: inline-block;
    background: white;
    position: relative;
    @if $border {
      top: -15px;
    } @else {
      top: 0;
    }
    margin: 0;
    @include responsive-spacing('padding-right', 2);
  }
}

.rover-content-section {
  @include rover-content-section;
  &.noborder {
    @include rover-content-section(false);
  }
}
