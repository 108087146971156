@import '../../../kibble/tokens/build/scss/tokens';

/* ==========================================================================
 * Message in a conversation
 * ========================================================================== */

.rover-message {
  $avatar-size: 50px;
  $arrow-width: 10px;
  $arrow-v-pos: 16px;
  $bg: white;
  $border-width: 1px;
  $avatar-placeholder-width: $avatar-size + $arrow-width + 8;

  @include clearfix;
  position: relative;
  padding-left: $avatar-placeholder-width;

  .rover-message-avatar-wrap {
    position: absolute;
    width: $avatar-size;
    bottom: 0;
    left: 0;

    .rover-message-avatar {
      @extend .img-circle;
      width: $avatar-size;
      height: auto;
    }
  }

  .rover-message-content-wrap {
    $message-padding: 10px;

    display: inline-block;
    position: relative;
    border-radius: $border-radius-base;
    border: $border-width solid $border-color;
    padding: $message-padding;
    background-color: $bg;
    max-width: 100%;
    word-wrap: break-word;

    &:before {
      content: '';
      bottom: $arrow-v-pos;
      left: ($arrow-width + 2 * $border-width - 1) * -1;
      position: absolute;
      border-top-width: $arrow-width - $border-width;
      border-right-width: $arrow-width + $border-width;
      border-bottom-width: $arrow-width - $border-width;
      border-left-width: 0;
      border-style: solid;
      border-color: transparent $border-color;
    }

    &:after {
      content: '';
      bottom: $arrow-v-pos + $border-width;
      left: -$arrow-width;
      border-top-width: $arrow-width - 2 * $border-width;
      border-right-width: $arrow-width;
      border-bottom-width: $arrow-width - 2 * $border-width;
      border-left-width: 0;
      border-color: transparent $bg;
      position: absolute;
      border-style: solid;
    }

    .rover-message-media {
      @include responsive-spacing('margin-bottom', 2);

      .rover-message-thumb {
        @include responsive-spacing('margin-bottom', 1);
      }

      &.hero {
        margin-left: -$message-padding;
        margin-right: -$message-padding;
        margin-top: -$message-padding;

        .rover-message-thumb {
          @extend .img-full-width;
          border-top-left-radius: $border-radius-base;
          border-top-right-radius: $border-radius-base;
        }
      }
    }
  }

  /* font-size in this class corresponds to kibble paragraph_200 */
  .rover-message-text {
    color: $text-color-primary;
    font-size: $typography-paragraph-200-font-size;
    @include responsive-spacing('margin-bottom', 2);
  }

  /* font-size in this class corresponds to kibble text_100 */
  .rover-message-meta-wrap {
    font-size: $typography-text-100-font-size;
  }

  .rover-message-meta {
    display: inline-block;
    @include responsive-spacing('margin-right', 1);
  }

  .rover-message-sender {
    font-family: $font-family-sans-serif;
    font-weight: bold;
  }

  .rover-message-time {
    color: $text-color-tertiary;
  }

  .message-link {
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $text-color-tertiary;
    }
  }

  /* Overrides for message bubble coming from right
     * ===================================================================== */
  &.rover-message-right {
    padding-left: 0;
    padding-right: $avatar-placeholder-width;

    .rover-message-avatar-wrap {
      left: auto;
      right: 0;
    }

    .rover-message-content-wrap {
      float: right;

      &:before {
        left: auto;
        right: ($arrow-width + 2 * $border-width - 1) * -1;
        border-top-width: $arrow-width - $border-width;
        border-right-width: 0;
        border-bottom-width: $arrow-width - $border-width;
        border-left-width: $arrow-width + $border-width;
      }

      &:after {
        left: auto;
        right: -$arrow-width;
        border-top-width: $arrow-width - 2 * $border-width;
        border-right-width: 0;
        border-bottom-width: $arrow-width - 2 * $border-width;
        border-left-width: $arrow-width;
      }
    }

    .rover-message-meta-wrap {
      text-align: right;
    }
  }
}
