.premier-sitter-intent {
  .premier-sitter-intent-checked {
    font-size: 60px;
    color: $secondary-1;
  }

  .premier-sitter-intent-sub-heading {
    height: 35px;
  }

  .premier-sitter-intent-lets-chat {
    font-size: 32px;
    line-height: 1em;
  }

  .premier-sitter-intent-phone-input {
    @include sm-only {
      width: 200px;
    }

    @include md-only {
      width: 250px;
    }

    @include lg-only {
      width: 300px;
    }
  }
}
