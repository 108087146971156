/* ==========================================================================
 * Rover's theme for Royal Slider
 *
 * This mostly just makes use of our loading icon and FontAwesome
 * instead of using the provided image sprite.
 *
 * Note: if you want to add support for other features of royal slider
 * such as  thumbnails, bullets, etc, grab the appropriate css from
 * vendor/royalslider/skins/rs-default.css
 * ======================================================================== */
.rover-media-gallery {
  .rsOverflow,
  .rsSlide,
  .rsVideoFrameHolder,
  .rsThumbs {
    background: #151515;
    color: #fff;
  }

  /* Nav Arrows
     * ===================================================================== */
  .rsArrow {
    height: 100%;
    width: 44px;
    position: absolute;
    display: block;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    z-index: 21;
  }

  &.rsVer .rsArrow {
    width: 100%;
    height: 44px;
  }

  &.rsVer .rsArrowLeft {
    top: 0;
    left: 0;
  }
  &.rsVer .rsArrowRight {
    bottom: 0;
    left: 0;
  }

  &.rsHor .rsArrowLeft {
    left: 0;
    top: 0;
  }
  &.rsHor .rsArrowRight {
    right: 0;
    top: 0;
  }

  .rsArrowIcn {
    width: 32px;
    height: 32px;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
    text-align: center;
    padding-top: 6px;

    position: absolute;
    cursor: pointer;

    color: #fff;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);

    border-radius: 2px;
  }

  .rsArrowIcn:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }

  /*
    NOTE(Ryan): the content properties in the two styles below are incredibly brittle,
                and will break if the order/naming of icons on IcoMoon changes
  */
  &.rsHor .rsArrowLeft .rsArrowIcn {
    &:before {
      content: '\e92b';
      font-family: 'rover-icons' !important;
    }
  }
  &.rsHor .rsArrowRight .rsArrowIcn {
    &:before {
      content: '\e940';
      font-family: 'rover-icons' !important;
    }
  }

  .rsArrowDisabled .rsArrowIcn {
    opacity: 0.2;
    filter: alpha(opacity=20);
    *display: none;
  }

  /* Preloader - use rover's loading icon
     * ===================================================================== */
  .rsPreloader {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    left: 50%;
    top: 50%;
    margin-left: -10px;
    margin-top: -10px;
    text-align: center;

    &:after {
      content: '';
      margin-top: 5px;
      @extend .rover-loading-icon;
    }
  }

  /* Captions
     * ===================================================================== */
  .rsGCaption {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    color: white;

    .rsCaption {
      display: inline-block;
      padding: 2px 8px;
      background: rgb(0, 0, 0);
      background: rgba(0, 0, 0, 0.75);
      font-size: 16px;
      border-radius: 2px;
      color: white;
    }
  }
}

/* Overrides for gallery in modal
 * =========================================================================*/
.rover-media-modal {
  // Make full screen
  .rover-media-gallery {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: auto;

    // Remove background color
    .rsOverflow,
    .rsSlide,
    .rsVideoFrameHolder,
    .rsThumbs {
      background: transparent;
    }
  }
}

/* Video Controls
 * ===================================================================== */

.rover-media-gallery {
  .rsPlayBtn {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
    width: 64px;
    height: 64px;
    margin-left: -32px;
    margin-top: -32px;
    cursor: pointer;
  }

  .rsPlayBtnIcon {
    position: relative;
    display: block;
    width: 64px;
    height: 64px;
    border-radius: $border-radius-base;
    background-color: rgba(0, 0, 0, 0.5);

    &:before {
      content: '\e93b';
      font-family: 'rover-icons' !important;
      color: white;
      font-size: 30px;
      position: absolute;
      top: 32px;
      left: 22px;
    }
  }

  .rsPlayBtn:hover .rsPlayBtnIcon {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .rsBtnCenterer {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .rsCloseVideoBtn {
    right: 0;
    top: 0;
    width: 44px;
    height: 44px;
    z-index: 500;
    position: absolute;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
  }

  .rsCloseVideoBtn.rsiOSBtn {
    top: -38px;
    right: -6px;
  }

  .rsCloseVideoIcn {
    margin: 6px;
    width: 32px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: $border-radius-base;

    &:before {
      content: '\e917';
      font-family: 'rover-icons' !important;
      color: white;
      font-size: 23px;
      position: absolute;
      top: 22px;
      left: 13px;
    }
  }

  .rsCloseVideoIcn:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }

  &.rsVideoPlaying {
    .rsPlayBtn {
      display: none;
    }

    .rsPreloader {
      margin-top: -30px;
      margin-left: -30px;
    }
  }
}
