.become-a-sitter-signup {
  color: $gray-5;

  .rover-page-header {
    margin-bottom: 24px;
  }

  .rover-header-title {
    @media (max-width: $screen-xs-max) {
      @include h2;
      margin-bottom: 8px !important;
    }
    @media (min-width: $screen-sm-min) {
      margin-bottom: 16px !important;
    }
  }

  .become-sitter-top-container {
    @media (max-width: $screen-xs-max) {
      margin-top: 48px;
    }
    @media (min-width: $screen-sm-min) {
      margin-top: 64px;
    }
  }

  .rover-header-subtitle-rover-makes-it-easy {
    margin-top: 16px;
  }

  .earn-up-to-header-title {
    @include h5;
    margin-bottom: 0;
  }

  .earn-up-to-header-subtitle {
    @include h2;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .want-more-help {
    margin-top: 16px;
  }

  .customer-quote-text {
    @include h4;
    margin: 11px 0;
  }

  .customer-quote-name {
    @include h6;
    margin: 11px 0 0 0;
  }

  .top-checklist-header {
    @media (max-width: $screen-xs-max) {
      margin-top: 48px;
      margin-bottom: 16px;
    }
    @media (min-width: $screen-sm-min) {
      margin-top: 64px;
    }
  }

  .how-it-works-xs {
    margin-top: 27px;
  }

  .how-it-works-lg {
    margin-top: 27px;
  }

  .horizontal-progress-title {
    @include h5;
  }

  .mobile-vertical-progress-title {
    @include h5;
    margin-top: 13px;
    margin-bottom: 4px;
  }

  .get-started-middle-box {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .get-started-bottom-box {
    margin-top: 5px;
    margin-bottom: 64px;
  }

  .receive-requests-profile-approved {
    @media (max-width: $screen-xs-max) {
      margin-top: 37px;
    }
    @media (min-width: $screen-sm-min) {
      margin-top: 64px;
    }
  }

  .we-work-tirelessly-box {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .bottom-checklist-box {
    @media (max-width: $screen-xs-max) {
      margin-top: 48px;
    }
    @media (min-width: $screen-sm-min) {
      margin-top: 64px;
    }
  }

  .bottom-checklist-box-xs-divider {
    margin-top: 47px;
  }

  .bottom-checklist-row {
    margin-top: 24px;
  }

  .bottom-checklist-icon {
    margin-right: 10px;
  }

  .bottom-checklist-heading {
    @include h5;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .bottom-checklist-badge {
    margin-left: 5px;
  }

  .horizontal-progress-title-box1 {
    @media (min-width: $screen-xs-max) and (max-width: $screen-sm-max) {
      padding-left: 28px;
      padding-right: 15px;
    }
    @media (min-width: $screen-sm-max) {
      padding-left: 55px;
      padding-right: 40px;
    }
  }

  .horizontal-progress-title-box2 {
    @media (min-width: $screen-xs-max) and (max-width: $screen-sm-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (min-width: $screen-sm-max) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .horizontal-progress-title-box3 {
    @media (min-width: $screen-xs-max) and (max-width: $screen-sm-max) {
      padding-left: 15px;
      padding-right: 28px;
    }
    @media (min-width: $screen-sm-max) {
      padding-left: 40px;
      padding-right: 55px;
    }
  }

  .safety-first-col {
    @media (max-width: $screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .top-checklist-row {
    background-color: $white;
  }

  .top-checklist {
    @media (min-width: $screen-sm-min) {
      min-height: 348px;
    }
  }

  .customer-quote-1,
  .customer-quote-2 {
    height: 360px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;

    @media (max-width: $screen-xs-max) {
      margin-top: 37px;
    }
  }

  .customer-quotes-external-row {
    @media (min-width: $screen-sm-min) {
      background-color: $gray-0;
    }
  }

  .gray-row {
    background-color: $gray-0;
  }

  .become-rover-quote-card-row2 {
    background-color: $gray-0;
  }

  .become-rover-quote-card {
    margin-top: -100px;
  }

  .become-rover-quote-card .card-block {
    padding: 24px;
  }

  .customer-quote-1 {
    background-image: url('../images/signup/quote_left.jpg');
  }

  .customer-quote-2 {
    background-image: url('../images/signup/quote_right.jpg');
  }

  header,
  .rover-header-subtitle {
    color: $gray-5;
  }

  .badge {
    background-color: $yellow-400;
  }

  .vertical-line-divider {
    @media (min-width: $screen-sm-min) {
      border-left: 1px solid #dddddd;
    }
  }

  .become-a-sitter-hero-header {
    @media (max-width: $screen-xs-max) {
      height: 160px;
    }
    @media (min-width: $screen-xs-max) {
      height: 424px;
    }
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    margin-left: 0;
    margin-right: 0;
  }

  .rover-tiny-check-list {
    li {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  /* Verical progress bar for mobile */
  .mobile-vertical-progress {
    @include clearfix;
    counter-reset: vertical-progress-counter;
    $marker-size: 28px;
    $marker-top: calc($line-height-computed / 2);
    padding-left: 15px;
    padding-right: 15px;
    @include xs-only {
      .vertical-progress-section {
        @include responsive-spacing('padding-left', 6);
        border-left: 1px solid $border-color;
        position: relative;
        width: 100%;
        float: left;
        min-height: 85px;

        &.vertical-progress-section-last::before {
          content: '';
          width: 3px;
          background: $gray-0;
          position: absolute;
          bottom: 0;
          left: -1px;
          top: calc($marker-top + $marker-size / 2);
        }

        &.vertical-progress-section-first::before {
          content: '';
          width: 3px;
          background: $gray-0;
          position: absolute;
          top: 0;
          left: -1px;
          height: calc($marker-top + $marker-size / 2);
        }

        & > .summary {
          color: $gray-3;
          opacity: 0;
          position: absolute;
          top: 50px;
          left: 30px;
          transition-delay: 0s;
          transition: opacity 0s;
        }

        & > .content {
          max-height: 1000px;
          overflow: hidden;
          transition: max-height 0.3s ease-in-out;
        }

        &.collapsed {
          & > .content {
            max-height: 0;
          }

          & > .summary {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
            transition-delay: 0.45s;
          }
        }
      }

      .vertical-progress-marker {
        background-color: white;
        line-height: $marker-size - 4;
        width: $marker-size;
        height: $marker-size;
        border-radius: calc($marker-size / 2);
        border: 1px solid $border-color;
        padding-left: 1px;
        padding-top: 1px;
        color: $gray-2;
        font-weight: 700;
        text-align: center;
        z-index: $zindex-navbar-fixed - 1;
        position: absolute;
        left: calc($marker-size / -2);
        top: $marker-top;
        font-size: 12px;

        &::after {
          content: counter(vertical-progress-counter);
          counter-increment: vertical-progress-counter;
        }

        &.active {
          color: $primary-1;
          border: 2px solid $primary-1;
          padding-left: 0;
          padding-top: 0;
        }

        &.completed {
          font-size: 10px;

          &::after {
            content: '\e912';
            font-family: 'rover-icons';
            font-weight: normal;
          }
        }
      }
    }
  }
}

.become-a-sitter-page-container {
  overflow: hidden;

  .become-a-sitter-hero-header {
    background-image: url('../../shared/images/become-a-sitter/become-a-sitter-hero-mobile.jpg');
    @media (min-width: $screen-xs-max) {
      height: 639px;
      padding-top: 350px;
      background-image: url('../../shared/images/become-a-sitter/become-a-sitter-hero.jpg');
    }
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.3);
    .button-as-link {
      font-weight: 700;
      color: $gray-base;
    }
  }

  header,
  .text-muted,
  .rover-header-subtitle {
    color: $white;
  }

  .become-a-sitter-top-cta-template {
    header,
    .rover-header-subtitle {
      color: $gray-5;
    }
  }
}

.become-a-sitter-seo {
  .become-a-sitter-header {
    background-image: url('../../shared/images/become-a-sitter/become-a-sitter-hero-mobile.jpg');
    @media (min-width: $screen-xs-max) {
      background-image: url('../../shared/images/become-a-sitter/become-a-sitter-hero.jpg');
    }
    height: 300px;
    background-size: cover;
    background-position-x: center;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.3);
    margin-left: 0;
    margin-right: 0;
  }

  .top-cta-block {
    @include responsive-spacing('margin-top', -5);
  }

  .become-rover-cta {
    @include responsive-spacing('margin-top', 2);
  }

  .become-a-rover-sitter {
    li {
      a {
        color: $text-color;
        text-decoration: underline;

        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }

  .checked-icon-list {
    margin-bottom: 0;

    li {
      margin-bottom: 20px;
      display: table-row;

      &:before {
        font-family: rover-icons;
        content: '\e625';
        font-size: 24px;
        padding-right: 10px;
        display: table-cell;
        vertical-align: top;
      }
      & > span {
        display: table-cell;
        padding-top: 8px;
      }
    }
  }

  .become-premier-support {
    a {
      color: $text-muted;
      text-decoration: underline;

      &:hover {
        color: $link-hover-color;
      }
    }
  }

  .sdv-phone-responsive-helper {
    @media (max-width: $screen-sm) {
      margin-bottom: 30px;
    }
  }
}
