.nps-capture-page {
  .nps-heading {
    margin-bottom: 20px;

    @include not-xs {
      margin-top: 40px;
    }

    @include xs-only {
      margin-top: 40px;
    }
  }

  .xs-labels {
    @include not-xs {
      display: none;
    }
  }

  .button-columns {
    @include xs-only {
      flex-direction: column-reverse;
    }

    label {
      margin-bottom: 20px;
    }
  }
}
