.meet-and-greet {
  overflow: hidden;
  width: 592px;
  z-index: 500;
  transition: height 0.25s;
}

.meet-and-greet-form {
  transition: margin-top 0.25s;
  &.behind-the-fold {
    margin-top: 30px;
  }
}
