/* ==========================================================================
 * Sitter Signup/Account Edit Styles
 * ========================================================================== */

@import '../base/colors';

@mixin new-label-pill() {
  @include small-text;
  @include responsive-spacing('margin-left', 2);
  @include responsive-spacing('padding-left', 1);
  @include responsive-spacing('padding-right', 1);
  @include responsive-spacing('margin-right', 1);
  background: $tertiary-2;
  color: white;
  border-radius: $border-radius-base;
  font-weight: normal;
  vertical-align: top;
}

@mixin new-section-background() {
  background: rgba($gray-lighter, 0.5);
}

@mixin new-section() {
  @include responsive-spacing('margin-left', -2);
  @include responsive-spacing('padding-left', 2);
  @include responsive-spacing('margin-right', -2);
  @include responsive-spacing('padding-right', 2);
  @include responsive-spacing('padding-top', 2);
  @include responsive-spacing('padding-bottom', 2);
  @include new-section-background;

  border-radius: $border-radius-base;

  &::before {
    @include new-label-pill;
    @include responsive-spacing('margin-bottom', 2);
    content: 'NEW';
  }
}

.new-label-pill {
  @include new-label-pill;
}

.field-changes-for-extra-services {
  @include new-section;
}

.account-profile {
  ul.promote-boarding-checklist {
    margin-top: 18px;
    margin-bottom: 0px;

    li {
      margin-top: 12px;
    }
  }

  ul.promote-boarding-checklist-profile {
    margin-bottom: 10px;
  }

  @include sm-up {
    .highest-earners-badge {
      margin-left: 5px;
    }
  }

  h2 {
    font-size: $font-size-h3;
  }

  h5.ssu-form-header {
    @include responsive-spacing(margin-top, 6);
  }

  .profile-photo-upload-wrap {
    @include responsive-spacing(margin-top, 6);

    h5 {
      margin-top: 0px;
    }
  }

  // Hide all * in the required field labels
  .asteriskField {
    display: none;
  }

  .rover-primary-content-block {
    box-shadow: none;
    padding: 25px;
    padding-top: 0px;

    > .row {
      @include xs-only {
        margin-left: calc($grid-gutter-width / -2);
        margin-right: calc($grid-gutter-width / -2);
      }
    }
  }

  .rover-page-header {
    display: none;
  }

  .rover-main-content-wrap {
    @include xs-only {
      .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .rover-widget-block {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .row-full-bleed {
    @include full-bleed;
  }

  // Highlight background of hero rows
  .hero-row.show-new-label {
    @include new-section-background;

    & + .hero-snap-on-row {
      @include new-section-background;
    }
  }

  .provider-profile-back-button {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  form {
    fieldset {
      @include responsive-spacing('margin-bottom', 4);

      .numberinput {
        width: 75px;
        float: left;
      }

      .numberinput + .help-block {
        display: inline-block;
        padding-left: 0.5em;
        vertical-align: middle;
        margin-top: 8px;
        margin-bottom: 7px;
        color: $text-color-tertiary;
      }
    }

    .phone-number-input {
      width: 250px;
    }

    .intl-tel-input,
    .phone-number-input.js-intl-tel-input {
      width: 100%;
    }
  }

  .service-form {
    .checkbox {
      @include xs-only {
        @include responsive-spacing('margin-left', -2);
      }

      label {
        @include responsive-spacing('padding-top', 2);
        font-weight: 400;
        text-transform: uppercase;
        display: block;
        color: $text-color-tertiary;
      }

      > label {
        @include xs-only {
          visibility: hidden;
        }
      }
    }

    .errorField {
      display: none;
    }
  }

  .crispy-hide-show-trigger-wrap .button-as-link {
    text-decoration: none;
  }

  .deactivate-service-button {
    white-space: normal;
  }

  .photo-pane {

    .edit-icon {
      display: none;
      position: absolute;
      top: 10px;
      right: 25px;
      cursor: pointer;
    }

    .delete-icon {
      display: none;
      position: absolute;
      bottom: 10px;
      right: 25px;
      cursor: pointer;
    }

    &:hover {
      .edit-icon {
        display: block;
      }
      .delete-icon {
        display: block;
      }
    }
  }

  .button-columns {
    &:not(.button-columns-inline-labels) {
      @include responsive-spacing(margin-top, 2, true);
    }
  }

  .form-group {
    .control-label {
      font-weight: 700;
    }
  }

  .checkbox {
    margin: 0;

    label {
      font-weight: 700;
    }
  }

  .account-profile-section-header {
    @include responsive-spacing('margin-top', 8);
    @include responsive-spacing('margin-bottom', 8);
    @include responsive-spacing('margin-left', 0);
    @include responsive-spacing('margin-right', 0);
    text-align: center;
  }

  .error-popover {
    z-index: $zindex-modal-background - 1;
  }
}

/* Account Profile Index
 * ===================================================================== */
.account-profile-index {
  .rover-widget-block {
    @include responsive-spacing('margin-left', 2);
    @include responsive-spacing('margin-right', 2);
  }

  .rover-widget-block.account-profile-widget {
    a { text-decoration: none; }
  }

  .rover-widget-block .rover-widget-block-inner-wide .rover-widget-body {
    min-height: 160px;

    @include xs-only {
      min-height: 0px;
    }
    @include sm-only {
      min-height: 0px;
    }
  }

  .section-links {
    li {
      @include responsive-spacing('margin-bottom', 2);
    }

    .status-icon {
      @include responsive-spacing('margin-right', 1);
      display: inline-block;
      vertical-align: middle;
      font-size: 1.5em;

      .rover-icon {
        color: $secondary-1;
        font-weight: bold;
      }

      .notify {
        @include h5;
        $notify-height: 20px;
        display: inline-block;
        background-color: $tertiary-2;
        color: white;

        height: $notify-height;
        width: $notify-height;
        border-radius: $notify-height;
        text-align: center;
        vertical-align: bottom;
        margin-bottom: 6px;
      }
    }
  }

  .extra-services-index-banner {
    @include responsive-spacing('padding', 2);

    background: $tertiary-2;
    color: white;
    text-align: center;

    .rover-btn {
      @include responsive-spacing('padding', 1);
      @include responsive-spacing('margin-left', 1);
    }
  }
}

/* Wizard header
 * ===================================================================== */
.signup-header {
  background: $gray-lighter;

  .signup-header-container {
    @include flexbox;
  }

  .section-link {
    @include responsive-spacing('padding-top', 4);
    @include responsive-spacing('padding-bottom', 4);
    @include flex(1, 1, auto); // grow, shrink, basis

    @include h5;

    @include xs-only {
      @include h2;
    }

    .section-name {
      @include xs-only {
        display: none;
      }
    }

    &.enabled {
      color: black;
    }
    &.disabled {
      color: $gray-light;
    }
    &.active {
      border-bottom: 5px solid $primary-1;
    }

    a {
      color: black;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.mobile-embedded.sections {
  .section-calendar {
    display: none;
  }

  .section-your-dogs {
    display: none;
  }
}

/* Image Cropping Modal Styles
 * ===================================================================== */
.rover-crop-modal {
  // Enforce a 3:2 aspect ratio
  .crop-container {
    @include fixed-aspect-ratio(3, 2);
    position: relative;
    overflow: hidden;

    .crop-container-inner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

/* Your Dogs Page
 * ===================================================================== */
.your-dogs-page {
  .control-label {
    font-weight: bold;
  }

  .next-step-button {
    @include responsive-spacing('margin-top', 3);
  }

  .pet-header {
    @include clearfix;
    @include responsive-spacing('margin-left', -1);
    @include responsive-spacing('margin-right', -1);
    margin-bottom: 20px;
    border-bottom: 1px solid $border-color;

    &.no-border {
      border-bottom: none;
    }

    .rover-primary-content-block {
      @include responsive-spacing('padding-top', 2);
      @include responsive-spacing('padding-bottom', 2);
      @include clearfix;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .pet-card-wrapper {
      @include make-sm-column(3, 0);
      @include responsive-spacing('padding', 1);

      a,
      a:hover,
      a:focus,
      a:active {
        text-decoration: none;
      }
    }

    .pet-card,
    .add-pet-button {
      height: 60px;
      position: relative;
      border-radius: $border-radius-base;
    }

    .pet-card {
      background: $gray;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      h3 {
        color: $text-color-link-inverse;
      }

      .pet-name {
        color: $text-color-primary-inverse;
        position: absolute;
        bottom: 5px;
        left: 5px;
      }

      .text-muted {
        color: $text-color-tertiary-inverse;
      }
    }

    .add-pet-button {
      border: 1px dashed $border-color;
      text-align: center;

      &:hover {
        color: black;
      }
    }
  }

  header.person-from-header {
    text-align: center;
  }

  #div_id_dog-gender {
    .button-columns {
      label {
        border-width: 1px;
        width: 50%;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-left: 0;
          border-radius: 0 4px 4px 0;
        }
      }

      .rover-btn {
        &:nth-child(2) {
          &.active {
            box-shadow: -1px 0 0 $primary-1;
          }
        }

        &.active {
          box-shadow: none;
        }

        .rover-icon {
          font-size: 14px;
        }
      }
    }

    .rover-item-checked {
      display: none;
    }

    .dog-gender-f,
    .dog-gender-m {
      padding: 8px;
    }
  }

  .dog-birthday-inputs {
    margin-left: 0px;

    div {
      &:first-child {
        input {
          border-radius: 4px 0 0 4px;
        }
      }

      &:last-child {
        input {
          border-left: 0;
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }

  #hint_id_dog-breeds {
    display: none;
  }

  .rover-images-container {
    margin-top: 20px;

    .sortable-ghost {
      img {
        opacity: 0.2;
      }

      .edit-icon {
        display: none;
      }

      .delete-icon {
        display: none;
      }
    }
  }

  .rover-images-placeholders {
    margin-top: 20px;
    img {
      border: 3px dashed $gray-lighter;
    }
  }

  textarea,
  input {
    resize: none;
  }
}

/* Account Info Page
 * ===================================================================== */
.account-info-page {
  .changes-for-extra-services {
    @include new-section;
  }

  .profile-photo-wrap {
    position: relative;
    display: inline-block;

    .edit-icon {
      display: none;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &:hover {
      .edit-icon {
        display: block;
      }
    }
  }
}

/* Photos page
 * ===================================================================== */
.photos-page {
  .cover-photo-wrap {
    .upload-icon {
      display: none;
      position: absolute;
      top: 10px;
      right: 25px;
    }

    img {
      width: 100%;
    }

    &:hover {
      .upload-icon {
        display: block;
      }
    }
  }
}

/* Donations Page
 * ===================================================================== */
.donations-page {
  #active-pledges {
    .row {
      margin: 4px 0px;
    }
  }

  #id_percentage {
    display: inline;
  }

  #tax-info {
    color: #555;
    padding-top: 5px;
    font-size: 11px;
  }

  ul {
    list-style: none;
  }

  p.pledge-statement {
    font-style: italic;
    font-weight: bold;

    select {
      width: 5em;
    }
  }
}

/* Preferences Page
 * ===================================================================== */
body.preferences-page {
  .rover-primary-content-block {
    .control-label {
      font-weight: bold;
    }

    form fieldset .numberinput {
      float: none;
      display: inline-block;
    }

    .rover-header-subtitle {
      font-weight: 400;
    }

    .hero-snap-on-row {
      @include responsive-spacing('padding-left', 6);
      @include responsive-spacing('padding-right', 6);
    }

    @media (max-width: $screen-xs-max) {
      .hero-row {
        @include responsive-spacing('padding-left', 6);
        @include responsive-spacing('padding-right', 6);
        .icon-label {
          margin: 5px 0 0 0;
          .text-label,
          br {
            display: none;
          }
        }
      }
    }
  }
}

/* Rates Page
 * ===================================================================== */
body.rates-page {
  .rover-main-content-wrap {
    .rates-header {
      text-align: center;
    }

    .form-control {
      @include responsive-spacing('margin', 1);
      display: inline-block;
      width: 35%;
      max-width: 90px;
      padding: 8px 8px 8px 18px;
    }

    .variable-rates {
      padding-left: 8px;
    }

    .services_dollars_widget {
      text-align: center;
    }

    input.offer_for_free {
      @include responsive-spacing('margin', 1);
      width: auto !important;
      height: inherit;
      float: left;
    }

    .servicesdurationdaysxwidget {
      @include responsive-spacing(margin-right, 1, true);
      width: 20%;
      max-width: 50px;
      padding: 8px;
    }

    .right {
      text-align: right;
    }

    .hero-row {
      @include responsive-spacing(padding-bottom, 2, true);

      .form-control {
        width: 57%;
      }

      &:first-child {
        margin-top: 0px;
      }

      .hero-image-gray {
        background: $gray-5;
      }
    }

    .hero-snap-on-row {
      .row {
        @include responsive-spacing(padding-top, 1, true);
        @include responsive-spacing(padding-bottom, 1, true);
      }

      display: none;
    }

    .before_text {
      @include responsive-spacing(margin-left, 1, true);
    }

    .submit-wrapper {
      text-align: center;
      @include responsive-spacing(margin-top, 8, true);
    }

    .not-offered {
      font-style: italic;
      color: $gray-light;
    }

    .additional-rates {
      text-decoration: none;

      @include responsive-spacing(margin-left, 8);

      @include xs-only {
        @include responsive-spacing(margin-left, 3);
      }
    }
  }
}

/* Notifications header
 * ===================================================================== */
body.settings-page {
  .rover-main-content-wrap {
    .rover-primary-col {
      .container {
        width: 100%;
      }
    }

    .tab-content {
      padding-top: 15px;
    }

    .form-group {
      border-top: 1px solid $gray-1;
      padding: 15px 0;
      margin: 0;

      &:first-of-type {
        border-top: none;
      }

      select.select.form-control {
        width: 200px;
      }
    }

    .continue-previous-form {
      border-top: 1px solid $gray-1;
      padding: 15px 0;
      margin: 0;
    }

    .quiet-hours-row {
      select.select.form-control {
        width: 100%;
      }
    }

    .fieldset > .form-group:nth-of-type(1) {
      border-top: none;
    }

    .form-group.notify_phone {
      @include responsive-spacing(padding-top, 2, true);
    }

    .quiet-hours-row {
      display: inline-block;
      @include responsive-spacing(padding-right, 5, true);

      .form-group {
        border-top: none;
      }
    }

    .col-checkbox {
      text-align: right;
    }

    .checkbox label {
      padding-left: 0px;
    }

    .settings-form-cta-row {
      display: flex;
      justify-content: space-between;
      @include xs-only {
        display: block;
        div.margin-top-x2 {
          padding-top: 16px;
        }
      }
    }
  }
}

/* Details Page
 * ===================================================================== */
body.details-page {
  .control-label {
    font-weight: bold;
  }

  .textarea-display {
    .textareawithwordcount {
      max-width: 100%;
    }
  }

  @include xs-only {
    .button-columns-3 {
      .icon-label br {
        display: none;
      }
    }
  }

  .rover-provider-profile-form,
  .dogs_experience {
    .control-label {
      @include responsive-spacing(margin-bottom, 1, true);
    }
  }

  .changes-for-extra-services {
    @include new-section;
  }

  #id_provider_profile-years_of_experience {
    width: 10%;
    min-width: 60px;
  }

  // disgusting hack to get jQuery validate to be less zealous
  #div_id_provider-description.force-no-error-styles {
    label,
    p {
      color: $gray-dark !important;
    }

    textarea {
      border-color: #ddd;
    }
  }
}

.header-notify {
  @include responsive-spacing('margin-left', 1);

  $notify-height: 16px;
  font-size: $font-size-small;
  font-weight: bold;
  display: inline-block;
  background-color: $tertiary-2;
  color: white;
  height: $notify-height;
  width: $notify-height;
  border-radius: $notify-height;
  line-height: $notify-height;
}

body.safety-quiz {
  .safety-quiz-status {
    border: 1px solid $border-color;
    @include responsive-spacing(padding, 4, true);
    @include responsive-spacing(margin-top, 3, true);
    @include responsive-spacing(margin-bottom, 3, true);
  }
}

body.background-check-page {
  .background-check-status {
    border: 1px solid $border-color;
    @include responsive-spacing(padding, 4, true);
    @include responsive-spacing(margin-top, 3, true);
    @include responsive-spacing(margin-bottom, 3, true);

    &.show-badge {
      @include responsive-spacing(margin-top, 10, true);
      @include responsive-spacing(padding-top, 8, true);
    }

    img {
      position: absolute;
      margin-left: -35px;
      margin-top: -75px;
      max-height: 70px;
    }
  }

  .rover-background-check-step {
    display: none;

    &.active {
      display: block;
    }

    .boxed {
      border: 1px solid $border-color;
      @include responsive-spacing(padding, 3, true);
      @include responsive-spacing(margin-bottom, 2, true);
      max-height: 400px;
      overflow: scroll;
    }

    table {
      td {
        border: 1px solid $border-color;
        font-size: 13px;
        max-width: 300px;
        vertical-align: text-top;
        @include responsive-spacing(padding, 1, true);
      }
    }
  }

  .rover-background-check-title-step-1 {
    display: block;
  }

  .rover-background-check-title-step-2 {
    display: none;
  }

  .rover-background-check-step-1 {
    @include responsive-spacing(padding, 5, true);

    .form-combined-fields {
      @include responsive-spacing(padding, 5, true);
    }
  }

  .rover-background-check-step-2 {
    @include responsive-spacing(padding, 5, true);
  }

  .rover-background-check-step-4 {
    .boxed {
      label {
        display: inline;
      }
    }
  }

  .submit {
    display: none;
    @include responsive-spacing(margin-top, 3, true);
  }

  .submit.optional {
    display: block;
  }

  .js-select-background-check-padding {
    padding: 25px;
  }

  .background-check-type-row {
    @include flexbox;
    @include flex-flow(row nowrap);
    @include align-items(center);
    @include responsive-spacing('margin-bottom', 8);

    div {
      @include responsive-spacing('padding-left', 3);
      @include responsive-spacing('padding-right', 3);
    }

    .background-check-type-radio {
      @include flex(0, 1, 0);
    }

    .background-check-type-image {
      @include flex(0, 1, 0);
    }

    .background-check-type-description {
      @include flex(1, 0, 0);

      p.large-text > .label {
        position: relative;
        top: -2px;
      }
    }

    img {
      width: 50px;
    }
  }

  .background-check-single {
    img {
      width: 72px;
    }
  }

  .js-show-background-check-btn-area {
    margin: 20px;
    text-align: center;

    & > a {
      cursor: pointer;
    }
  }
}

body.calendar-page {
  .rover-header-group {
    margin-bottom: 0;
  }

  .availability-preferences-form-wrap {
    @include responsive-spacing(margin-left, 6);

    @include xs-only {
      margin-left: 0px;
    }

    .rover-btn:focus-within {
      @include tab-focus(4, 0);
    }

    // Hide the fieldset legend except from screenreaders
    .control-label {
      @extend .sr-only;
    }
  }

  .sync-your-calendar,
  .account-calendar-sidebar-links a {
    text-decoration: none;
  }
}

body.remove-service-page {
  textarea {
    height: 100px;
    max-width: 100%;
  }
}

.phone-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

body.phones-page {
  .verify-form {
    .form-group {
      margin-bottom: 0px;
    }
  }

  .phone-actions {
    @include not-xs {
      @include flexbox;
      @include justify-content(space-between);
    }

    div {
      @include xs-only {
        width: 100%;
        text-align: right;
      }

      @include not-xs {
        @include flex(1, 0, 30%);
        text-align: center;
        &:last-child {
          text-align: right;
        }
      }
    }

    div.make-default {
      @include not-xs {
        @include flex(1, 0, 40%);
      }
    }
  }
}

.services-page {
  .switcher-checkbox:checked + .switcher-label {
    @include responsive-spacing('margin-left', -1);
    padding-left: 0px;
  }
  .rover-header-group {
    @include responsive-spacing('margin-bottom', 2);
  }
  .additional-form {
    margin-left: calc($grid-gutter-width / -2);
    margin-right: calc($grid-gutter-width / -2);

    .crispy-hide-show-trigger-wrap button {
      margin-left: 2em;

      @include xs-only {
        margin-left: 0px;
      }
    }
    .crispy-hide-show-content {
      @include responsive-spacing('padding-top', 2);
      border-top: 1px solid $border-color;
    }
    .crispy-hide-show-content,
    .crispy-hide-show-trigger-wrap {
      padding-left: calc($grid-gutter-width / 2);
      padding-right: calc($grid-gutter-width / 2);
    }
    .switcher {
      @include responsive-spacing('margin-top', 4);
      @include xs-only {
        @include responsive-spacing('margin-left', -2);
      }
    }
    .button-as-link {
      text-decoration: none;
    }
  }
}

/* Promote Page
 * ===================================================================== */
body.promote-page {
  .control-label {
    font-weight: bold;
    &[for='id_service'] {
      display: none;
    }
    &[for='id_title'],
    &[for='id_post_body'] {
      float: left;
    }
  }

  a.shop-rover-store,
  .share-profile-online a {
    text-decoration: none;
  }

  .copy-button {
    float: right;
    padding: 2px 8px;
    font-size: 12px;
  }

  .craigslist-preview-modal .modal-dialog {
    width: 80%;
  }
}

/* Testimonials Page
 * ===================================================================== */
.more-email-addresses {
  text-decoration: none;
}

/* Account Profile Page (/account/profile/phones/)
 * Correction to the left padding of Phone Number Input - https://github.com/jackocnr/intl-tel-input/issues/965
 * ===================================================================== */
.iti {
  width: inherit;
}
