/* ==========================================================================
 * Styles for Magnific Photo Galleries
 * ========================================================================== */

// Overrides due to specificity problems
.mfp-arrow {
  margin-top: -55px;
  &:active {
    margin-top: -54px;
  }
}

.mfp-close {
  font-size: $font-size-h3;
}

.mfp-zoom-out-cur {
  cursor: default;
  .mfp-close {
    cursor: pointer;
  }
}

// Fade In/Out Effect
.mfp-bg,
.mfp-wrap {
  transition: all 0.25s ease-out;
  opacity: 0;
  &.mfp-ready {
    opacity: 1;
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  &.mfp-removing {
    opacity: 0;
  }
}

// Special modal for sharing photos
.js-rover-modal {
  .mfp-bottom-bar {
    height: 60px;
    margin-top: -150px;
    .mfp-title {
      margin-top: 6px;
    }
  }

  .social-share-icon-wrapper {
    width: 48px;
    height: 48px;
    margin: 0 3px;
    display: inline-block;
    border-radius: 3px;

    .fa {
      display: table-cell;
      vertical-align: middle;
    }

    .rover-icon {
      display: table-cell !important;
    }
  }

  .hide-photo-button {
    display: inline-block;
    margin-top: -24px;
  }

  .hidden-photo img {
    opacity: 0.3;
  }

  .mfp-caption-display {
    margin-left: 10px;
  }

  .facebook-share-outer {
    background-color: $facebook-color;
  }

  .twitter-share-outer {
    background-color: $twitter-color;
  }

  .pinterest-share-outer {
    background-color: $pinterest-color;
  }

  .gallery-image {
    position: relative;
    @include xs-only {
      img {
        width: 100%;
      }
    }
  }

  .hover-caption {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 4px;
    -webkit-font-smoothing: auto;
  }
}

// Dogs image
.dogs-image-gallery {
  li {
    text-align: center;
  }

  .hover-caption {
    display: block;
  }
}

// Dog Image Gallery ('member/----/dogs')
.dog-image-gallery {
  min-height: 500px;

  &__column {
    float: left;
  }

  &__item {
    overflow: hidden;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: auto;
      opacity: 0;
      -webkit-transition: opacity 0.25s linear;
      transition: opacity 0.25s linear;
    }

    &.loaded {
      img {
        opacity: 1;
      }
    }

    &-desc {
      position: absolute;
      bottom: 5px;
      left: 5px;
      z-index: 50;
      opacity: 0;
      -webkit-transition: opacity 0.25s linear;
      transition: opacity 0.25s linear;
      color: white;
    }

    &:hover {
      .dog-image-gallery__item-desc {
        opacity: 1;
        color: white;
      }
    }
  }
}
