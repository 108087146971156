.step-icon {
  width: 48px;
  height: 48px;
  margin-left: auto;
  margin-right: 0;
  border-radius: 50%;
  border: 1px solid $gray-1;
  @include xs-only {
    margin-right: auto;
    margin-top: 14px;
    margin-bottom: 14px;
  }
  img {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 20px;
    height: 24px;
  }
}

.on-demand-cta {
  .card-block {
    @include responsive-spacing('margin-left', 3);
    @include responsive-spacing('margin-right', 3);
  }
}

.step-card {
  @include xs-only {
    text-align: center;
  }
}

.no-jumbo-header-xs {
  @include xs-only {
    .jumbo-centered {
      position: relative !important;
      top: 0 !important;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    .card {
      border: none;
    }
    .card-block {
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 0;
    }
    background-image: none !important;
    background-color: inherit;
    min-height: 0 !important;
    position: relative !important;
    margin-top: 0 !important;
    padding: 0 !important;
  }
}
