@import '../../../kibble/tokens/build/scss/tokens';

.new-design {
  .payments-table {
    .gross-payment {
      font-weight: bold;
      font-size: $font-size-h4;
      text-align: right;
      padding-right: 20px;
      @include not-xs {
        padding-right: 0;
      }
      @include xs-only {
        font-size: $font-size-large;
      }
    }

    .dates {
      white-space: nowrap;
      padding-left: 20px;
      @include not-xs {
        padding-left: 0;
      }
    }

    .details {
      padding-left: 20px;
      @include not-xs {
        padding-left: 0;
      }
    }

    .payment {
      border-bottom: 1px solid $gray-lighter;
    }

    .payments-empty {
      border: 1px dashed $gray-light;
      text-align: center;
    }
  }

  .balance-widget-body {
    font-family: $typography-text-100-font-family;
    padding-bottom: $size-space-1-x;

    .balance-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $size-space-4-x;
      margin-bottom: $size-space-2-x;
      border-radius: $size-border-radius-secondary;

      font-size: $size-font-100;
      font-weight: 600;
      line-height: $typography-text-100-line-height;

      .stacked-label {
        display: flex;
        flex-direction: column;
      }

      .balance-label {
        color: $color-text-secondary;
        text-transform: uppercase;
      }

      a {
        color: $color-interactive-text-link-primary-base;
        padding-top: $size-space-2-x;
      }

      .balance {
        color: $color-text-primary;
        font-size: $size-font-300;
      }

      &.neutral {
        background-color: $color-neutral-100;
      }

      &.accent {
        background-color: $color-yellow-100;
      }
    }

    .processing-notice {
      color: $color-text-tertiary;
      font-size: $size-font-100;
      font-weight: $typography-text-100-font-weight;
      line-height: $typography-paragraph-100-line-height;
      margin-bottom: $size-space-2-x;
    }

    .action-required-notice {
      color: $color-text-error !important;
      a {
        font-weight: $weight-font-semibold;
      }
    }
  }

  .gus-says {
    .rover-message-content-wrap {
      border-top: 5px solid $tertiary-2;
    }
  }

  .donations {
    border-radius: 4px;
    border: 1px solid $border-color;
    padding: 10px;
    div {
      position: relative;

      a {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }

  @include xs-only {
    .return-link {
      text-align: center;
    }

    .payment-history-header {
      padding-left: 15px;
    }
  }

  .receipt {
    border: 1px solid $gray-lighter;

    .fulfilled {
      border: 1px solid $brand-success;
      color: $brand-success;
    }

    .failed {
      border: 1px solid $brand-danger;
      color: $brand-danger;
    }

    .pending {
      border: 1px solid $brand-info;
      color: $brand-info;
    }

    .details {
      border-bottom: 1px solid $gray-lighter;

      &:last-of-type {
        border: none;
      }
    }

    .status-indication {
      float: right;
    }

    @include xs-only {
      .payout-status,
      .payout-total {
        text-align: left;
        float: left;
      }

      .status-indication {
        float: left;
      }
    }
  }

  .muted-label {
    display: block;
    color: $text-muted;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  .receipt-label {
    @extend .muted-label;
    margin-top: 0;
  }

  .receipt-value,
  .promo-subheading {
    font-weight: bold;
    font-size: $font-size-large;
  }

  .submit-btn {
    margin-top: 25px;
  }

  @include xs-only {
    .return-link {
      text-align: center;
    }

    .xs-btn-block {
      width: 100%;
      margin-top: 0;
    }
  }

  .divided-item {
    border-top: 1px solid $gray-lighter;
  }

  .separated {
    border-right: 2px solid $gray-lighter;
    @include xs-only {
      border-right: none;
    }
  }
}

.account-payments-promos {
  .asteriskField {
    display: none;
  }
}

.rover-primary-content-block {
  @include responsive-spacing('padding', 6);
  @media (max-width: $screen-xs-max) {
    @include responsive-spacing('padding', 3);
    @include responsive-spacing('padding-top', 6);
  }
  .rover-page-header {
    @include responsive-spacing('margin-bottom', 3);
  }
}

.redeem-receipt-header {
  color: $gray;
}

.redeem-receipt {
  @media (max-width: $screen-xs-max) {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}


.found-partnership-widget {
  .fintech-notice {
    color: $color-text-tertiary;
    font-size: $size-font-100;
    font-weight: $typography-text-100-font-weight;
    line-height: $typography-paragraph-100-line-height;
    margin-bottom: $size-space-2-x;
  }

}