/* ==========================================================================
 * Contact Confirmation Styles
 * ========================================================================== */

@import '../../../kibble/tokens/build/scss/tokens';

.contact-confirmation {
  $check-icon-size: 24px;

  /* Sitter select interface
     * ===================================================================== */

  .sitter-selects-wrap {
    // Don't show validation popovers here
    .error-popover {
      display: none !important;
    }
  }

  .sitter-select {
    position: relative;
    padding: 5px 0 0 10px;
  }

  // Sitter select checkbox label overlays sitter card to intercept clicks
  .sitter-select-checkbox-label {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    z-index: 2; //must sit on top to intercept clicks
  }

  .sitter-select-check {
    z-index: 1; // must sit below label to avoid click firing
    position: absolute;
    top: -10px;
    left: -10px;
    color: transparent;
    background: $color-interactive-background-primary-base;
    border: 2px solid $color-interactive-border-primary-base;
    border-radius: $size-border-radius-secondary;
    height: $check-icon-size;
    text-align: center;
    line-height: $check-icon-size;
    width: $check-icon-size;
  }

  // Selected checkbox styling
  .sitter-select.selected {
    .sitter-select-check {
      border: none;
      display: block;
      color: white;
      background: $color-neutral-800;
    }
  }

  // Sitter card customizations
  .sitter-card {
    // Force constrained version
    @include sitter-card-constrained-overrides;

    min-height: 0;
    border: none;

    .search-index {
      display: none;
    }
    .sitter-actions {
      display: none;
    }
  }

  /* Next Step Styles
     * ===================================================================== */

  .next-step-icon {
    width: 30px;
    text-align: right;
  }

  .next-step-new-icon {
    width: 30px;
    text-align: right;
    margin-right: 16px;
  }

  /* Misc
     * ===================================================================== */

  .success-icon {
    color: $brand-success;
  }

  /* Large Screen Overrides
     * ===================================================================== */

  @include sm-up {
    // Move checkmark to the left of the sitter card
    .sitter-select {
      padding-left: 25px;
    }

    // On large screens the label and the indicator are
    // the same size adn in the same place
    .sitter-select-checkbox-label,
    .sitter-select-check {
      bottom: auto;
      right: auto;
      width: $check-icon-size;
      height: $check-icon-size;
    }
  }

  @include xs-only {
    .next-step.referral {
      text-align: center;
    }

    .next-step-icon.referral-icon {
      float: none !important;
      width: 100%;
      text-align: center;
      margin-bottom: 16px;
    }

    .next-step-new-icon.referral-icon {
      float: none !important;
      width: 100%;
      text-align: center;
      margin-bottom: 16px;
    }
  }
}
