@import '../utilities/variables';
@import '../vendor/bootstrap/scss/mixins';

// Cards
$card-spacer-x: 20px !default;
$card-spacer-y: 20px !default;
$card-border-width: 1px !default;
$card-border-radius: $border-radius-base !default;
$card-border-color: #e5e5e5 !default;
$card-border-radius-inner: $card-border-radius !default;
$card-cap-bg: #f5f5f5 !default;
$card-bg: #fff !default;

$card-link-hover-color: #fff !default;

$card-deck-margin: 0.625rem !default;

// Card variants

@mixin card-variant($background, $border) {
  background-color: $background;
  border-color: $border;
}

@mixin card-outline-variant($color) {
  background-color: transparent;
  border-color: $color;
}

//
// Inverse text within a card for use with dark backgrounds
//

@mixin card-inverse {
  .card-header,
  .card-footer {
    border-bottom: $card-border-width solid rgba(255, 255, 255, 0.2);
  }
  .card-header,
  .card-footer,
  .card-title,
  .card-blockquote {
    color: #fff;
  }
  .card-link,
  .card-text,
  .card-blockquote > footer {
    color: rgba(255, 255, 255, 0.65);
  }
  .card-link {
    @include hover-focus {
      color: $card-link-hover-color;
    }
  }
}

//
// Base styles
//

.card {
  position: relative;
  display: block;
  margin-bottom: $card-spacer-y;
  background-color: $card-bg;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  // Community Card
  &.community-card {
    display: inline-flex !important;
    margin-bottom: 0;

    @include xs-only {
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
    }

    > .card-block {
      position: relative;
      border-radius: 4px;
      height: 375px;
      min-width: 320px;
      max-width: 320px;
      margin: 0 auto;
      overflow: hidden;
      background-position: center center;
      background-size: cover !important;

      .clickable {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
      }

      @include xs-only {
        height: 236px;
      }

      @include media-breakpoint-up(sm) {
        min-width: 320px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 260px;
        min-width: 0;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
        z-index: 1;
      }

      > .card-text {
        max-width: 100%;
        width: 100%;
        position: relative;
        z-index: 10;
      }
    }
  }
}

.card-block {
  @include clearfix;
  padding: $card-spacer-x;

  .card-block-border {
    width: 40px;
    height: 2px;
    background-color: $primary-1;
    margin: 0 0 20px;
  }
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: calc($card-spacer-y / -2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

// .card-actions {
//   padding: $card-spacer-y $card-spacer-x;

//   .card-link + .card-link {
//     margin-left: $card-spacer-x;
//   }
// }

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

@if $enable-rounded {
  .card {
    > .list-group:first-child {
      .list-group-item:first-child {
        border-radius: $card-border-radius $card-border-radius 0 0;
      }
    }

    > .list-group:last-child {
      .list-group-item:last-child {
        border-radius: 0 0 $card-border-radius $card-border-radius;
      }
    }
  }
}

//
// Optional textual caps
//

.card-header {
  @include clearfix;
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-border-radius-inner $card-border-radius-inner 0 0);
  }
}

.card-footer {
  @include clearfix;
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-border-radius-inner $card-border-radius-inner);
  }
}

//
// Background variations
//

.card-primary {
  @include card-variant($brand-primary, $brand-primary);
}
.card-success {
  @include card-variant($brand-success, $brand-success);
}
.card-info {
  @include card-variant($brand-info, $brand-info);
}
.card-warning {
  @include card-variant($brand-warning, $brand-warning);
}
.card-danger {
  @include card-variant($brand-danger, $brand-danger);
}

// Remove all backgrounds
.card-outline-primary {
  @include card-outline-variant($btn-primary-bg);
}
.card-outline-secondary {
  @include card-outline-variant($btn-secondary-border);
}
.card-outline-info {
  @include card-outline-variant($btn-info-bg);
}
.card-outline-success {
  @include card-outline-variant($btn-success-bg);
}
.card-outline-warning {
  @include card-outline-variant($btn-warning-bg);
}
.card-outline-danger {
  @include card-outline-variant($btn-danger-bg);
}

//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
  @include card-inverse;
}

//
// Blockquote
//

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0;
}

// Card image
.card-img {
  // margin: -1.325rem;
  @include border-radius(0.25rem);
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

// Card image caps
.card-img-top {
  @include border-radius($card-border-radius-inner $card-border-radius-inner 0 0);
}
.card-img-bottom {
  @include border-radius(0 0 $card-border-radius-inner $card-border-radius-inner);
}

//
// Card set
//

@if $enable-flex {
  @include media-breakpoint-up(sm) {
    .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -$card-deck-margin;
      margin-left: -$card-deck-margin;

      .card {
        flex: 1 0 0;
        margin-right: $card-deck-margin;
        margin-left: $card-deck-margin;
      }
    }

    .chp.horizontal-progress {
      display: flex !important;
    }
  }

  @include media-breakpoint-down(xs) {
    .chp.horizontal-progress {
      display: none;
    }
    .chp.card-block {
      padding: 0px 0px 0px 20px;
      .card-image {
        float: left;
        width: 40px;
        height: 40px;
        margin: 5px auto;
      }
      .card-block {
        text-align: left;
        margin-left: 40px;
        padding: 0px 0px 0px 12px;
      }
    }
  }
} @else {
  @include media-breakpoint-up(sm) {
    $space-between-cards: (2 * $card-deck-margin);
    .card-deck {
      display: table;
      table-layout: fixed;
      border-spacing: $space-between-cards 0;

      .card {
        display: table-cell;
        width: 1%;
        vertical-align: top;
      }
    }
    .card-deck-wrapper {
      margin-right: (-$space-between-cards);
      margin-left: (-$space-between-cards);
    }
  }
}

//
// Card groups
//

.card-group.vertical {
  @if $enable-flex {
    flex-flow: column;
  } @else {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .card {
    @include media-breakpoint-up(md) {
      &:not(:last-child) {
        margin-bottom: 0;
        border-bottom: none;
      }

      @if $enable-rounded {
        &:first-child {
          @include border-bottom-radius(0);
        }

        &:not(:first-child) {
          @include border-top-radius(0);
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .card-group:not(.vertical) {
    @if $enable-flex {
      display: flex;
      flex-flow: row wrap;
    } @else {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .card {
      @if $enable-flex {
        flex: 1 0 0;
      } @else {
        display: table-cell;
        vertical-align: top;
      }

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:first-child {
          @include border-right-radius(0);

          .card-img-top {
            border-top-right-radius: 0;
          }
          .card-img-bottom {
            border-bottom-right-radius: 0;
          }
        }
        &:last-child {
          @include border-left-radius(0);

          .card-img-top {
            border-top-left-radius: 0;
          }
          .card-img-bottom {
            border-bottom-left-radius: 0;
          }
        }

        &:not(:first-child):not(:last-child) {
          border-radius: 0;

          .card-img-top,
          .card-img-bottom {
            border-radius: 0;
          }
        }
      }
    }
  }
}

//
// Card
//

@include media-breakpoint-up(sm) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;

    .card {
      display: inline-block;
      width: 100%; // Don't let them exceed the column width
    }
  }
}

// Community Cards
// ** A flexbox layout for multiple community cards ** //
.community-cards {
  & > .title {
    margin-bottom: 30px;
  }

  & > .col-md-3 {
    display: flex;
    justify-content: center;

    @include xs-only {
      margin-left: -15px;
      padding-right: 0;
    }
  }
}

// Rebooking Provider Cards
.rebooking-profile-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-block {
    a {
      text-decoration: none;
    }
  }

  .rating-stars {
    margin-bottom: 0;
  }
}
