.app {
  .alert {
    top: 35px;
  }

  .hero-container {
    position: relative;
    width: 100%;

    .hero {
      @include img-retina(
        '../images/app/get-the-app-img-mobile.jpg',
        '../images/app/get-the-app-img-mobile@2x.jpg',
        375px,
        200px
      );

      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover !important;
      display: block;
      height: 730px;
      text-align: center;
      width: 100%;

      @include sm-up {
        @include img-retina(
          '../images/app/get-the-app-img.jpg',
          '../images/app/get-the-app-img@2x.jpg',
          1440px,
          400px
        );
      }

      @include xs-only {
        height: 200px;
      }
    }

    .hero-overlay-container {
      position: absolute;
      top: 170px;
      width: 100%;
      display: flex;
      justify-content: center;

      @include lg-only {
        justify-content: flex-start;
        padding-left: 148px;
      }

      @include xs-only {
        position: relative;
        top: -30px;
      }

      .hero-overlay {
        width: 557px;
        max-width: calc(100% - 40px);
        padding: 48px;
        border-radius: 16px;
        background-color: white;
        color: #1b1f23;
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
        text-align: center;

        @include lg-only {
          text-align: left;
        }

        @include sm-down {
          padding: 32px;
        }

        @include xs-only {
          margin-top: -30px;
        }

        .send-to-phone-container {
          display: flex;

          @include xs-only {
            display: none;
          }

          .send-to-phone-column {
            display: flex;
            flex-grow: 1;
            flex-direction: column;

            @include lg-only {
              flex-grow: initial;
            }

            .send-to-phone-row {
              display: flex;
              flex-grow: 1;

              @include lg-only {
                flex-grow: initial;
              }

              .iti {
                flex-grow: 2;

                .send-to-phone-phone-number {
                  width: 100%;
                }
              }

              .send-to-phone-btn {
                flex-grow: 1;
                margin-left: 10px;
                border-radius: 4px;

                @include lg-only {
                  border-radius: 999px;
                }
              }
            }
          }
        }

        .app-store-buttons-container {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-top: 48px;

          @include xs-only {
            margin-top: 32px;
          }

          @include lg-only {
            justify-content: flex-start;
          }

          .app-store-button {
            height: 38px;

            &:first-of-type {
              margin-right: 16px;
            }
          }
        }

        .app-availability-text {
          width: 100%;
          margin-top: 8px;
          text-align: center;
          color: $text-color-tertiary;

          @include lg-only {
            text-align: left;
          }
        }
      }
    }
  }

  .copy-container {
    display: flex;
    padding: 20px;
    font-size: 20px;

    @include xs-only {
      flex-direction: column;
      padding-top: 12px;
      padding-bottom: 50px;
    }

    @include sm-only {
      padding-top: 26px;
      padding-bottom: 64px;
    }

    @include md-only {
      padding: 85px;
      padding-top: 58px;
      padding-bottom: 96px;
    }

    @include lg-only {
      padding: 148px;
      padding-top: 58px;
      padding-bottom: 96px;
    }

    .copy-section {
      & > h2 {
        text-align: center;
      }

      &:first-of-type {
        margin-right: 30px;
      }

      p:not(:first-of-type) {
        margin-top: 48px;
      }
    }
  }
}
