// Horizontal Progress //
// Progress steps in horizontal fashion styleguide component
// * This component supersedes the .wizard-section-header classes used currently * //

// Component Classes

// Optional Classes

@import '../utilities/flexbox';

.horizontal-progress {
  @include flexbox;
  @include responsive-spacing('margin-top', 4);

  position: relative;
  flex-direction: row;

  &__step {
    display: flex; // grow, shrink, basis
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    color: $gray-6;
    position: relative;
    z-index: 1;

    &.missing-instructions {
      color: $secondary-1;
    }

    a {
      display: block;
      width: 100%;
      &:hover {
        text-decoration: none;
      }
    }

    &.active {
      color: $green-600;
      a {
        color: $green-700;
        &:hover {
          text-decoration: none;
        }
      }
    }

    &__heading {
      font-size: 12px;
      line-height: 1.25;
      color: $gray-5;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 120%;

      // Active State
      .active & {
        font-weight: 700;
      }

      .anchor & {
        color: $primary-1;
      }

      .missing-instructions & {
        color: $secondary-1;
      }

      // .first & {
      //     text-align: left;
      //     transform: translateX(-15%);
      // }
      //
      // .last & {
      //     text-align: right;
      //     transform: translateX(-85%);
      // }
    }

    &__line {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 100%;

      &:after,
      &:before {
        content: '';
        position: absolute;
        height: 1px;
        border-bottom: 1px solid $gray-1;
        width: 50%;
        z-index: 0;
        top: 50%;
        transform: translateY(-1px);

        .passive & {
          border-bottom: 1px solid $gray-1;
        }
      }

      // Left line
      &:before {
        left: 0;

        .first & {
          display: none !important;
        }
      }

      // Right line
      &:after {
        right: 0;

        .last & {
          display: none !important;
        }
      }
    }

    &__number {
      display: flex;
      height: 32px;
      width: 32px;
      text-align: center;
      border: 1px solid;
      border-color: $gray-1;
      border-radius: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 1;
      background-color: $white;
      position: relative;
      z-index: 10;

      .active & {
        border-width: 2px;
      }

      .missing-instructions & {
        background-color: $secondary-1;
        color: $white;
        font-size: 14px;
      }
    }
  }
}
