.star-rating-widget {
  font-size: 45px;
  text-align: center;

  .star-rating-off {
    color: $gray-1;
  }

  .star-rating-on {
    color: $notice;
  }
}

.star-rating-widget input {
  &:focus-visible {
    +label {
      @include tab-focus();
    }
  }
}

.action-star-rating-widget {
  font-size: 35px;
  text-align: center;
  color: $primary-1;
}

.labeled-star-rating-widget {
  text-align: center;

  .star-rating-off {
    color: $gray-light;
  }

  div,
  h4 {
    float: none;
    display: inline-block;
    vertical-align: middle;
  }
}

.rate-sitter-form {
  text-align: center;

  .rating-form-container {
    padding: 20px;
  }

  .public-feedback-container {
    background-color: $gray;
    color: white;
    border-radius: $border-radius-base;
  }
}

.rate-qm-walker-form {
  text-align: left;

  .rating-form-container {
    padding: 0px 20px 5px 20px;
  }

  .rating-form-toggle-container {
    text-align: left;
    margin: 15px 0px 0px 0px;

    .tooltip-icon {
      color: $primary-1;
    }

    .form-group {
      border-bottom: 1px solid $gray-1;
    }
  }

  .rating-form-toggle-container label {
    padding: 0px;
    font-weight: 700;
  }

  .public-feedback-container {
    background-color: $gray;
    color: white;
    border-radius: $border-radius-base;
  }

  .push_up {
    margin-top: -20px;
  }
}

.invisible-checkbox {
  position: absolute;
  z-index: -1;
  @include opacity(0);
}

.textarea-display {
  .textarea {
    max-width: 100%;
  }
}
