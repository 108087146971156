/* ==========================================================================
 * Styles for the Sitter Card, which is a small summary view of the sitter
 * ========================================================================== */

/* Sitter Info Styling - used by sitter card
 * ===================================================================== */

@import '../utilities/mixins';
@import '../../../kibble/tokens/build/scss/tokens';

$sitter-card-padding: 15px;

.sitter-price-section {
  .price-wrap {
    display: inline-block;
    float: right;
  }

  .price {
    display: inline-block;
    position: relative;
    font-size: 30px;
    line-height: 1.2;
  }

  .currency-symbol {
    font-size: 18px;
    line-height: 1.2;
  }
}

.sitter-info-section {
  &>.sitter-card-title-area {
    font-size: 24px;
    margin-right: 5px;
    color: $color-interactive-text-link-secondary-base;

    :hover {
      color: $color-interactive-text-link-secondary-pressed;
    }
  }

  &>.sitter-card-badges {
    font-size: 16px;
    white-space: nowrap;
    position: relative;
    top: -2px;
  }

  &>.sitter-desc {
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-top: -5px;
    margin-bottom: 0;
  }
}

.sitter-price-section-reduced {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &>a {
    white-space: nowrap;
    display: flex;
  }
}

.sitter-attributes {
  margin-top: 10px;
  padding-right: 10px;
  line-height: 1.65;

  .rover-icon-in-check {
    color: $notice;
  }

  .sitter-attribute {
    display: block;
    white-space: normal;
    @include text-muted;
    margin-right: 5px;
    padding-left: 21px;
  }

  .rover-icon {
    font-size: 20px;
    position: relative;
    top: 2px;
    margin-left: -21px;
  }
}

.review-stars {
  color: $notice;
  margin-right: 7px;
  margin-top: 4px;
}

.review-stars-reduced {
  color: $notice;
  font-size: 18px;
  margin-right: 7px;

  &>i {
    margin-right: -3px;
  }
}

@mixin sitter-tag() {
  padding: 2px 4px;
  margin: 2px 6px 0 0;
  display: inline-block;
  border-radius: 4px;
  text-transform: uppercase;
  z-index: 2;
  position: sticky;

  &:hover {
    text-decoration: none;
  }
}

.review-count {
  @include sitter-tag;
  border: 1px solid $color-background-tertiary;
  background-color: $color-background-tertiary;
  color: $color-text-secondary;

  &:hover {
    border-color: $neutral-300;
    background-color: $neutral-300;
  }

  &.not-reviewed {
    border-color: $color-border-primary;

    &:hover {
      background-color: white;
      @include text-muted;
    }
  }
}

.breed-count {
  @include sitter-tag;
  border: 1px solid rgba($quick-match, 0.3);

  &:hover {
    background-color: $quick-match;
  }
}

.review-count-reduced {
  margin-top: 2px;
}

.sitter-review-wrap {
  .review-img {
    margin: 2px 5px 0 0;
  }

  .review-content {
    margin-bottom: 0;
    line-height: normal;
  }
}

.sitter-badge {
  padding: 0 5px 5px 0;
}

/* Sitter Card
 * ===================================================================== */

.sitter-card {
  padding: $sitter-card-padding;
  margin-top: 0;
  position: relative;

  // The search results container has a border-bottom, so
  // last card doesn't need one
  &:last-child {
    border-bottom: none;
  }

  // CSS ribbon
  .ribbon-callout {
    $ribbon-color: fade_out($text-color-secondary, 0.9);
    @include responsive-spacing('padding', 1);
    @include small-text;
    background: $ribbon-color;
    color: $text-color-secondary;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $sitter-card-padding;
    margin-top: 5px;
    max-width: 60%;
    border-radius: 25px;
    border: 1px solid;

    @include sm-only {
      max-width: 80%;
    }

    @include xs-only {
      display: none;
    }
  }

  // CSS ribbon - recently_updated_calendar_experiment
  .ribbon-callout-experiment {
    @include responsive-spacing('padding', 1);
    margin-bottom: $sitter-card-padding;
    border-radius: 4px;
    overflow: hidden;
    color: $text-color-secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    padding: 8px 16px;
    display: flex;
  }

  .ribbon-callout-with-dates {
    $ribbon-color: fade_out(#e5f8f0, 0);
    background: $ribbon-color;
    text-align: left;
  }

  .ribbon-callout-without-dates {
    text-align: center;
    border: solid 1px #dddddd;
  }

  @include sm-up {
    font-size: 14px;
  }

  @include xs-only {
    font-size: 12px;
  }

  .ribbon-left-icon {
    float: left;
    padding-right: 1px;
    padding-left: 1px;
    margin-left: 1px;
    position: relative;
    top: -2px;
  }

  .ribbon-center-content-icon {
    display: inline;
    overflow: hidden;
    margin-right: 7px;
  }

  .ribbon-center-content-text {
    display: inline;
    overflow: hidden;
    vertical-align: middle;
  }

  .ribbon-left-content {
    float: left;
    overflow: hidden;
    margin-left: 10px;
  }

  .ribbon-right-content {
    float: right;
    overflow: hidden;
  }

  .sitter-card-body-main {
    position: relative;
    table-layout: fixed;

    $sitter-card-price-col-width: 50px;

    .sitter-card-price-col {
      width: $sitter-card-price-col-width;
    }
  }

  .sitter-photo-section {
    position: relative;
    margin-right: 15px;

    .person-video-callout {
      position: absolute;
      top: 5px;
      right: 5px;
      height: 30px;
      line-height: 30px;
      width: 30px;
      z-index: 1;

      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      text-align: center;
      color: white;
    }

    .fb-friend-wrap {
      max-width: 150px;

      .media-object {
        margin-right: 5px;
        padding-top: 1px;
      }
    }

    .item {
      overflow: hidden;
      height: 150px;
      width: 150px;

      img {
        width: 100%;
      }
    }
  }

  .sitter-info-section {
    .search-index {
      font-size: 0.9em;
    }

    .sitter-earned-badges {
      img {
        margin-right: 5px;
        vertical-align: baseline;

        @include xs-only {
          max-width: 16px;
          height: auto;
        }
      }
    }

    .sitter-card-badges {
      &>.sitter-earned-badges {
        img {
          vertical-align: sub;
        }
      }
    }

    .review-summary-wrap {
      @include xs-only {
        margin-top: 5px;
      }
    }

    .sitter-review-wrap {
      padding: 10px 0 10px;
    }
  }

  .sitter-price-section {
    // Make room for availability banners
    padding-top: 30px;
  }

  .sitter-card-footer {
    @include not-xs {
      border-top: 1px solid lighten($border-color, 10%);
      padding-top: 10px;
    }
  }

  .sitter-attributes {
    margin-bottom: 0;
  }

  .sitter-save {
    // @fragile - breaking the save button out of the footer
    // mobile because rest of footer content is hidden
    position: absolute;
    bottom: $sitter-card-padding;
    right: $sitter-card-padding;
    text-align: center;
  }
}

// Constrained sitter card styles
// This has to be a mixin because it wi applied both by a media
// query and a parent class
@mixin sitter-card-constrained-overrides() {
  min-height: 120px;

  .sitter-attributes {
    margin-top: 0;
  }

  .ribbon-callout {
    display: none;
  }

  .sitter-card-body {
    overflow-wrap: break-word;
  }

  .sitter-card-body-main {
    position: static;
  }

  .hidden-constrained {
    display: none;
  }

  .sitter-photo-section {
    .person-video-callout {
      width: 15px;
      height: 15px;
      line-height: 15px;
      font-size: 0.5em;
    }

    .sitter-carousel-wrap {
      width: 75px;
    }

    .carousel-control {
      display: none;
    }

    .item {
      width: 75px;
      height: 75px;
    }

    .fb-friend-wrap {
      display: none;
    }
  }

  .sitter-info-section {
    display: block;

    &>.sitter-card-title-area {
      font-size: 18px;
    }

    .sitter-earned-badges {
      img {
        vertical-align: top;
      }
    }

    .sitter-card-badges {
      &>.sitter-earned-badges {
        img {
          vertical-align: sub;
        }
      }
    }

    .sitter-card-badges {
      img {
        width: 20px;
      }
    }
  }

  .sitter-card-title {
    font-size: 18px;

    a {
      color: $color-interactive-text-link-secondary-base;
      text-decoration: none;

      &:hover {
        color: $color-interactive-text-link-secondary-pressed;
        text-decoration: underline;
      }
    }
  }

  .sitter-services {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: normal;
    color: $gray-4;
  }

  .sitter-location {
    margin-bottom: 2px;
  }

  .sitter-desc {
    display: none;
  }

  .review-summary-wrap {
    margin-top: 5px;
  }

  .sitter-review-wrap {
    display: none;
  }

  .sitter-price-section {
    padding-top: 0;

    .price {
      font-size: 24px;
    }
  }

  .sitter-card-footer {
    margin: 10px 0 5px;
    padding-top: 5px;
    border: 0;

    .save-button-copy {
      display: none;
    }
  }
}

// Define small-screen styles
.sitter-card {
  @include xs-only {
    @include sitter-card-constrained-overrides;
  }

  @include not-xs {
    &.dilla-disabled {
      opacity: 0.1;
      pointer-events: none;
    }
  }
}
