.rover-table {
  @extend .table;
  @extend .table-striped;

  &:not(.unbordered) {
    @extend .table-bordered;
  }

  > thead > tr > th {
    border-bottom: 3px solid $gray-1;
    font-size: 16px;
    font-weight: normal;
  }
  > tbody > tr:nth-child(odd) {
    > td,
    > th {
      background-color: #fff;
    }
  }
  > tbody > tr:nth-child(even) {
    > td,
    > th {
      background-color: $table-bg-accent;
    }
  }
}

.rover-table-light {
  @extend .table;
  > tbody > tr > td {
    border-top: 1px solid $gray-1;
    border-bottom: 1px solid $gray-1;
  }
}
