.hero-row-container {
  border-collapse: collapse;
  .hero-row {
    padding-top: 15px;
    border: 1px solid $gray-1;

    .rover-header-group {
      @include responsive-spacing('padding-top', 1);
      @include responsive-spacing('padding-bottom', 1);

      .rover-header-title {
        font-weight: bold;
      }
      .rover-header-subtitle {
        font-weight: 400;
        line-height: 1.6;
      }
      @include not-xs {
        margin-left: 2em;
      }
    }

    .hero-image {
      position: absolute;
      left: -2em;

      @include xs-only {
        display: none;
      }
    }

    div.hero-image {
      $hero-image-size: 56px;
      text-align: center;
      background-color: $primary-1;
      color: white;
      border-radius: 28px;
      width: $hero-image-size;
      height: $hero-image-size;

      i.hero-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        font-size: 28px;
      }

      i.rover-icon-rover-paw {
        left: -2px;
      }

      &.hero-image-quick-match {
        background: $quick-match;
      }

      &.inactive {
        background-color: #ccc;
      }

      i.hero-image-badge {
        $hero-image-badge-size: 25px;
        width: $hero-image-badge-size;
        height: $hero-image-badge-size;
        border-radius: $hero-image-badge-size;
        line-height: $hero-image-badge-size;

        background: $gray-5;
        position: absolute;
        bottom: -6px;
        right: -6px;
        vertical-align: middle;
        padding-left: 1px;
        padding-top: 1px;
      }
    }
  }

  .hero-row:first-child {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
  }

  .hero-row:nth-child(n + 1):not(:nth-last-child) {
    border-top: 0;
  }

  .hero-row:last-child {
    border-top: 0;
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
  }

  .hero-row:only-child {
    border-radius: $border-radius-base;
    border: 1px solid $gray-1;
  }
}
