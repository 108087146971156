.promo-flag {
  display: inline-block;
  padding: 7px 10px 6px 10px;
  text-transform: uppercase;
  background-color: $notice;
  color: $notice-accent;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  border-radius: $border-radius-base;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.24);
}
