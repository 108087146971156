$pill-border-radius: 20px !default;
$pill-line-height: 1 !default;
$pill-margin-x: 10px !default;
$pill-margin-y: 15px !default;

$pill-bg: $white !default;
$pill-border: $gray-1 !default;
$pill-font: $gray-2 !default;

$pill-hover-bg: rgba($gray-1, 0.3);
$pill-hover-font: $gray-5;

$pill-active-bg: $white !default;
$pill-active-border: $primary-1 !default;
$pill-active-font: $gray-5 !default;

.btn-group-pills {
  .btn {
    line-height: $pill-line-height;
    border-radius: $pill-border-radius;

    margin-bottom: $pill-margin-y;
    margin-left: $pill-margin-x;

    border-width: 1px;
    border-style: solid;
    border-color: $pill-border;
    background-color: $pill-bg;
    color: $pill-font;

    &:hover {
      background-color: $pill-hover-bg;
      color: $pill-hover-font;
    }

    &.active,
    &:active {
      border-width: 2px;
      border-color: $pill-active-border;
      background-color: $pill-active-bg;
      color: $pill-active-font;
      box-shadow: none;
      padding: 7px 14px;
    }
  }
}
